import { useEffect, useRef } from 'react';

export const useIfNothingNull = (callback, dependencies, condition) => {
  const calledAlready = useRef(false);

  useEffect(() => {
    if (calledAlready.current) {
      return;
    }

    if (condition(dependencies)) {
      callback(dependencies);

      calledAlready.current = true;
    }
  }, [callback, condition, dependencies]);
};

export default useIfNothingNull;
