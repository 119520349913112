export const chanceRoll = (weight) => {
  let clamped = weight;
  if (clamped < 0) {
    clamped = 0;
  } else if (clamped > 1) {
    clamped = 1;
  }
  return Math.random() < clamped;
};

export const getRandomInt = (_min, _max) => {
  const min = Math.ceil(_min);
  const max = Math.floor(_max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const makeArray = (len) => {
  return [...Array(len).keys()];
};

export const makeRandomlyOrderedArray = (len) => {
  const array = [...Array(len).keys()];
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const selectRandom = (len, pick) => {
  const array = [];
  for (let i = len; i > 0; i -= 1) {
    const j = pick[getRandomInt(0, pick.length - 1)];
    array.push(j);
  }
  return array;
};

export const shuffleArray = (array) => {
  const modified = array;
  for (let i = modified.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [modified[i], modified[j]] = [modified[j], modified[i]];
  }
  return modified;
};

export const weight = (arr) => {
  return [].concat(
    ...arr.map((obj) => Array(Math.ceil(obj.weight * 100)).fill(obj))
  );
};

export const pickWeightedRandom = (arr) => {
  const weighted = weight(arr);
  return weighted[Math.floor(Math.random() * weighted.length)];
};

// eslint-disable-next-line new-cap
export const questionPicker = (() => {
  let pickedAlready = [];

  return {
    pick: (questions, _n = 3) => {
      if (pickedAlready.length === questions.length) {
        pickedAlready.length = 0;
      }

      const notYetPicked = questions.filter(
        (question) => pickedAlready.some((id) => id === question.id) === false
        // eslint-disable-next-line function-paren-newline
      );

      const picked = shuffleArray(notYetPicked).slice(0, _n);
      pickedAlready = [
        ...picked.map((question) => question.id),
        ...pickedAlready
      ];

      return picked || null;
    }
  };
})();
