import {
  OBSTACLES,
  COLLECTIBLES,
  PLAYER,
  ALERT,
  LOCK,
  FOLDER,
  MAGNIFY,
  SHIELD,
  YELLOW,
  ORANGE,
  PURPLE,
  GREEN,
  KO,
  FALL,
  RUN,
  JUMP,
  IDLE,
  SLIDE
} from '../../settings';

export default ({ sceneKey, loadScene }) => {
  /**
   * Runs at scene preload time, called by Phaser internally
   */
  function preload() {
    // Entities
    /* TODO: enable usage of local uris to avoid having to store assets in the public directory. */
    this.load.spritesheet(PLAYER, `../../assets/sprites/player.png`, {
      frameWidth: 45,
      frameHeight: 45
    });

    this.load.image(KO, `../../assets/sprites/ko.png`);

    this.load.image(SLIDE, `../../assets/sprites/slide.png`);

    this.load.spritesheet(
      COLLECTIBLES,
      `../../assets/sprites/collectibles.png`,
      {
        frameWidth: 13,
        frameHeight: 16
      }
    );

    this.load.spritesheet(OBSTACLES, `../../assets/sprites/obstacles.png`, {
      frameWidth: 13,
      frameHeight: 16
    });

    // Backgrounds
    const numberOfBackgroundSprites = 10;

    this.load.image(`floor_tile`, `../../assets/sprites/floor_tile.png`);

    for (let i = 0; i < numberOfBackgroundSprites; i++) {
      this.load.spritesheet(
        `frame_${i}`,
        `../../assets/sprites/frame_${i}.png`,
        {
          frameWidth: 98,
          frameHeight: 104
        }
      );
    }

    // Styles
    this.load.css(`phaser`, `../../../global-styles/phaser.css`);

    // Audio
    this.load.audio(`music`, `../../assets/music/music.mp3`);

    this.load.setPath(`../../assets/sounds`);
    for (let i = 0; i < 14; i++) {
      this.load.audio(`sound${i + 1}`, `sound${i + 1}.mp3`);
    }
  }

  /**
   * Runs at scene creation time, called by Phaser internally
   */
  function create() {
    // Entities
    this.anims.create({
      key: ALERT,
      frames: this.anims.generateFrameNumbers(COLLECTIBLES, {
        frames: [0]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: LOCK,
      frames: this.anims.generateFrameNumbers(COLLECTIBLES, {
        frames: [1]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: FOLDER,
      frames: this.anims.generateFrameNumbers(COLLECTIBLES, {
        frames: [2]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: MAGNIFY,
      frames: this.anims.generateFrameNumbers(COLLECTIBLES, {
        frames: [3]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: SHIELD,
      frames: this.anims.generateFrameNumbers(COLLECTIBLES, {
        frames: [4]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: YELLOW,
      frames: this.anims.generateFrameNumbers(OBSTACLES, {
        frames: [0]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: ORANGE,
      frames: this.anims.generateFrameNumbers(OBSTACLES, {
        frames: [1]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: PURPLE,
      frames: this.anims.generateFrameNumbers(OBSTACLES, {
        frames: [2]
      }),
      frameRate: 0,
      repeat: -1
    });

    this.anims.create({
      key: GREEN,
      frames: this.anims.generateFrameNumbers(OBSTACLES, {
        frames: [3]
      }),
      frameRate: 0,
      repeat: -1
    });

    // Player
    this.anims.create({
      key: IDLE,
      frames: this.anims.generateFrameNumbers(PLAYER, {
        frames: [0]
      }),
      frameRate: 2,
      repeat: -1
    });

    this.anims.create({
      key: RUN,
      frames: this.anims.generateFrameNumbers(PLAYER, {
        frames: [1, 2]
      }),
      frameRate: 16,
      repeat: -1
    });

    this.anims.create({
      key: JUMP,
      frames: this.anims.generateFrameNumbers(PLAYER, {
        frames: [2]
      }),
      frameRate: 8,
      repeat: -1
    });

    this.anims.create({
      key: FALL,
      frames: this.anims.generateFrameNumbers(PLAYER, {
        frames: [3]
      }),
      frameRate: 8,
      repeat: -1
    });

    // Backgrounds
    const framesMap = [
      {
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      },
      {
        // frames: [0, 1, 2, 0, 2, 1, 1, 0, 2, 1, 0],
        frames: [0],
        frameRate: 4
      },
      {
        // frames: [0, 1, 2],
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      },
      {
        frames: [0],
        frameRate: 1
      }
    ];

    for (let i = 0; i < framesMap.length; i++) {
      const { frames, frameRate } = framesMap[i];

      this.anims.create({
        key: `frame_${i}`,
        frames: this.anims.generateFrameNumbers(`frame_${i}`, {
          frames
        }),
        frameRate,
        repeat: -1
      });
    }

    this.scene.start(loadScene);
  }

  return {
    key: sceneKey,
    preload,
    create
  };
};
