/* eslint-disable no-extra-parens */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import parse from 'html-react-parser';
import { Carousel } from '../elements/Carousel';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Heading } from '../elements/Heading';
import { Button } from '../elements/Button';
import tutorialRun from '../../../assets/images/tut-run.png';
import tutorialJump from '../../../assets/images/tut-jump.png';
import tutorialSlide from '../../../assets/images/tut-slide.png';
import tutorialVirus from '../../../assets/images/tut-virus.png';
import tutorialCollectibles from '../../../assets/images/tut-collectibles.png';
import tutorialQuiz from '../../../assets/images/tut-quiz.png';
import closeGraphic from '../../../assets/images/close-button.png';
import chevronUp from '../../../assets/images/chevron-up.png';
import chevronDown from '../../../assets/images/chevron-down.png';

const CarouselSlide = ({
  text,
  textWithHTML,
  image,
  imageLandscape = false
}) => {
  return (
    <div className="max-w-[30em] h-[80%] m-auto self-center">
      <div className="h-full flex justify-center">
        <div className="ml-8 lg:ml-0 mr-4 lg:mr-16 min-w-[4em] lg:min-w-[8em] self-center">
          {imageLandscape === true ? (
            <img
              className="w-full h-auto max-w-[10rem] pixel-img"
              src={image}
              alt={text}
            />
          ) : (
            <img
              className="w-full h-auto max-w-[7rem] pixel-img"
              src={image}
              alt={text}
            />
          )}
        </div>
        <div className="text-left self-center text-l lg:text-xl font-pixel uppercase">
          {typeof textWithHTML !== `undefined` ? (
            parse(textWithHTML)
          ) : (
            <ReactMarkdown>{text}</ReactMarkdown>
          )}
        </div>
      </div>
    </div>
  );
};

export const Tutorial = ({ onGameStart = () => {}, onClose = () => {} }) => {
  return (
    <Content expand tintBackground className="relative z-50">
      <Panel>
        <Heading className="pb-2 lg:pb-8">How To Play</Heading>
        <div className="absolute top-7 right-1 lg:top-10 lg:right-10">
          <Button
            className="w-[70%] lg:w-auto"
            shadow={false}
            onClick={onClose}>
            <img src={closeGraphic} alt="Close" />
          </Button>
        </div>
        <Carousel onPlayButtonClicked={onGameStart}>
          <CarouselSlide
            text="Keep running to build your score"
            image={tutorialRun}
          />
          <CarouselSlide
            textWithHTML={`Tap <img className="pixel-img inline-pixel-chevron" src=${chevronUp} alt="Up" /> or W to single jump`}
            image={tutorialJump}
          />
          <CarouselSlide
            textWithHTML={`Tap <img className="pixel-img inline-pixel-chevron" src=${chevronUp} alt="Up" /> or W twice to double jump`}
            image={tutorialJump}
          />
          <CarouselSlide
            textWithHTML={`Tap <img className="pixel-img inline-pixel-chevron" src=${chevronDown} alt="Up" /> or S to slide`}
            image={tutorialSlide}
            imageLandscape
          />
          <CarouselSlide text="Avoid viruses" image={tutorialVirus} />
          <CarouselSlide
            text="Collect for bonuses"
            image={tutorialCollectibles}
          />
          <CarouselSlide
            text="Answer the post quiz questions to boost your score"
            image={tutorialQuiz}
          />
        </Carousel>
      </Panel>
    </Content>
  );
};

export default Tutorial;
