import Entity from '../entities/entity';
import { createEntities } from '../factories/entityFactory';
import { getRandomInt } from '../../helpers/chance';
import {
  gameSettings,
  entityTypeData,
  baseEntitySize,
  spawnPatterns
} from '../../settings';

export const resetGrid = (context) => {
  // Create a visual grid to hold the positions for potential spawns
  const gridWidth = 2;
  const gridHeight = 5;
  const startPadding = 600;

  // Decrease to increase space between spawns
  const spacePaddingFraction = 1.5;
  const screenFractionX = gameSettings.screenSize.width / spacePaddingFraction;

  /**
   * Instantiate the pool of collectibles and obstacles to use through the session and
   * tell them to sleep until required. Also duplicates in relation to the grid size
   * to avoid issues with not enough entities to respawn.
   */
  let typeDatas = [];

  for (let i = 0; i < gridWidth; i++) {
    typeDatas = [...typeDatas, ...entityTypeData];
  }

  const sceneEntities = createEntities.call(context, typeDatas, Entity);

  const distanceBetweenColumns = screenFractionX + baseEntitySize / 2;
  let lastGridX = 0;

  const yPadding = 18;

  for (let i = 0; i < gridWidth; i++) {
    for (let j = 0; j < gridHeight; j++) {
      const currentGridX = Math.floor(screenFractionX * i + baseEntitySize / 2);
      const distanceFromRight = gameSettings.screenSize.width - currentGridX;

      lastGridX = distanceFromRight;

      if (j === 0) {
        const nextPattern =
          spawnPatterns[getRandomInt(0, spawnPatterns.length - 1)];

        const cellHeight =
          gameSettings.screenSize.height / nextPattern.length - 0.5;

        for (let p = 0; p < nextPattern.length; p++) {
          const currentEntry = nextPattern[p];

          if (currentEntry === 1) {
            const getFirstInactive = sceneEntities.getFirstInactiveType({
              type: `obstacle`,
              shuffleOnSearch: true
            });

            const yPos = cellHeight * p;
            const offsetYPos = (yPadding + yPos) * 0.9;

            sceneEntities.respawnNextEntity(
              currentGridX + startPadding,
              offsetYPos,
              getFirstInactive
            );
          }
        }
      }
    }
  }

  const spawnAtPosition =
    gameSettings.screenSize.width - lastGridX + distanceBetweenColumns;

  return {
    sceneEntities,
    spawnAtPosition
  };
};
