import Phaser from 'phaser';
import { gameSettings } from '../../settings';

export const initKeys = (sceneContext, playerInstance) => {
  sceneContext.input.keyboard
    .addKey(Phaser.Input.Keyboard.KeyCodes.UP)
    .on(`down`, () => playerInstance.jump(-gameSettings.playerJumpStrength));

  sceneContext.input.keyboard
    .addKey(Phaser.Input.Keyboard.KeyCodes.W)
    .on(`down`, () => playerInstance.jump(-gameSettings.playerJumpStrength));

  sceneContext.input.keyboard
    .addKey(Phaser.Input.Keyboard.KeyCodes.DOWN)
    .on(`down`, () => playerInstance.crouch());

  sceneContext.input.keyboard
    .addKey(Phaser.Input.Keyboard.KeyCodes.S)
    .on(`down`, () => playerInstance.crouch());

  return {
    remove: () => {
      sceneContext.input.keyboard.removeKey(
        Phaser.Input.Keyboard.KeyCodes.UP,
        true,
        true
      );

      sceneContext.input.keyboard.removeKey(
        Phaser.Input.Keyboard.KeyCodes.W,
        true,
        true
      );

      sceneContext.input.keyboard.removeKey(
        Phaser.Input.Keyboard.KeyCodes.DOWN,
        true,
        true
      );

      sceneContext.input.keyboard.removeKey(
        Phaser.Input.Keyboard.KeyCodes.S,
        true,
        true
      );
    },
    jumpSignal: () => {
      playerInstance.jump(-gameSettings.playerJumpStrength);
    },
    slideSignal: () => {
      playerInstance.crouch();
    }
  };
};
