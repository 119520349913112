import React, { useState } from 'react';
import { LOCK, ALERT, FOLDER, MAGNIFY, SHIELD } from '../../../settings';
import { Button } from './Button';

const emojiMap = {
  [LOCK]: `🔒`,
  [ALERT]: `🚨`,
  [FOLDER]: `📁`,
  [MAGNIFY]: `🔍`,
  [SHIELD]: `🛡`
};

const getEmojis = (collected) => {
  let emojiString = ``;
  collected.forEach((collectible) => {
    emojiString = emojiString.concat(emojiMap[collectible.key]);
  });
  return emojiString;
};

const getCopyText = ({ score, collectibles }) => `
AXIS SAFE ROOM SPRINT\n\nSCORE: ${score}\nCOLLECTABLES: ${
  collectibles.length
}/5\n
${getEmojis(collectibles)}\n\ncybersaferoomsprint.axiscapital.com
`;

export const ShareButton = ({
  className = ``,
  score,
  collectibles = [],
  disabled = true,
  onShare = () => {}
}) => {
  const [didShare, setDidShare] = useState(false);

  const handleDidShare = () => {
    if (didShare) {
      return;
    }

    if (navigator.clipboard) {
      const filteredCollectibles = collectibles.filter(
        ({ collected }) => collected === true
      );

      navigator.clipboard.writeText(
        getCopyText({ score, collectibles: filteredCollectibles })
      );

      setDidShare(true);
      onShare();
    }
  };

  return (
    <Button
      disabled={disabled}
      theme="blue"
      className={className.concat(` pixel-font`)}
      onClick={handleDidShare}>
      {didShare ? `Copied!` : `Share`}
    </Button>
  );
};

export default ShareButton;
