import React, { useState, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Profanity, ProfanityOptions } from '@2toad/profanity';
import words from '../../../assets/data/profanity.json';
import { Busy } from './Busy';
import { NameEntry } from '../elements/NameEntry';
import { Heading } from '../elements/Heading';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Button } from '../elements/Button';

import { pad, replaceAt } from '../../../helpers/interface';
import closeGraphic from '../../../assets/images/close-button.png';
import { saveUserScore } from '../../../api/api';

export const SubmitScore = ({
  score,
  collectiblesGained = 0,
  maxCharacters = 5,
  maxCollectibles = 5,
  isDesktop = false,
  onRestart = () => {},
  onSubmitScore = () => {},
  onClose = () => {}
}) => {
  const filter = useMemo(() => {
    const options = new ProfanityOptions();
    options.wholeWord = false;
    const instance = new Profanity(options);
    instance.addWords(Object.keys(words));
    return instance;
  }, []);

  const [isBusy, setIsBusy] = useState(false);
  const [name, setUserName] = useState(``);
  const [invalidEntry, setInvalidEntry] = useState(true);
  const [userScore] = useState(pad(score, 8));

  const handleOnNameEntry = (char, index) => {
    const charactersOnly = /^[A-Za-z0-9_-]*$/;

    if (!charactersOnly.test(char)) {
      return;
    }

    setUserName((prev) => {
      const str = replaceAt(prev, char, index);
      setInvalidEntry(filter.exists(str) || str.length !== maxCharacters);
      return str;
    });
  };

  const handleOnSubmitScore = async () => {
    setIsBusy(true);

    await saveUserScore({
      name,
      score
    });

    setIsBusy(false);
    onSubmitScore(name);
  };

  return (
    <Content expand className="relative z-50">
      <Panel>
        <Busy isBusy={isBusy}>
          <Heading className="pb-2 lg:pb-8">Submit Score</Heading>
          <div className="absolute top-7 right-1 lg:top-10 lg:right-10">
            <Button
              className="w-[70%] lg:w-auto"
              shadow={false}
              onClick={onClose}>
              <img src={closeGraphic} alt="Close" />
            </Button>
          </div>
          <ReactMarkdown
            className="pb-2 lg:pb-6 font-pixel font-medium text-xs lg:text-base text-application-dark-blue uppercase"
            // eslint-disable-next-line
            children={`Your score: ${userScore}`}
          />
          <ReactMarkdown
            className="pb-2 lg:pb-6 font-pixel font-medium text-xs lg:text-base text-application-dark-blue uppercase"
            // eslint-disable-next-line
            children={`Collectibles: ${collectiblesGained}/${maxCollectibles}`}
          />
          <ReactMarkdown className="pb-1 lg:pb-6 font-inter font-medium text-xs lg:text-base">
            Enter your player ID to claim your spot on the leaderboard.
          </ReactMarkdown>
          <NameEntry
            maxCharacters={maxCharacters}
            isDesktop={isDesktop}
            onNameEntry={handleOnNameEntry}
          />
          <div className="flex justify-center mt-1 lg:mt-12">
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              theme="green"
              onClick={onRestart}>
              Replay
            </Button>
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              disabled={invalidEntry}
              theme="blue"
              onClick={handleOnSubmitScore}>
              Submit Score
            </Button>
          </div>
        </Busy>
      </Panel>
    </Content>
  );
};

export default SubmitScore;
