export const pad = (num, size) => String(num).padStart(size, `0`);

export const toIntArray = (size) => [...Array(size).keys()];

export const randomString = () => {
  let result = ``;
  const characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  const charactersLength = characters.length;
  for (let i = 0; i < 5; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const replaceAt = (str, char, index) => {
  if (index >= str.length) {
    return str + char;
  }
  return str.substring(0, index) + char + str.substring(index + 1);
};

export const intWithin = (value, min, max) => value >= min && value <= max;

export const getWindowDimensions = () => ({
  w: window.innerWidth,
  h: window.innerHeight
});

export const createFloatyText = (sceneContext, message, opts = {}) => {
  const { transition } = opts;
  const { from, to } = transition;

  const text = sceneContext.add.text(from.x, from.y, message, opts);

  text.setScale(0.5);

  sceneContext.tweens.add({
    targets: text,
    props: {
      x: {
        value: to.x
      },
      y: {
        value: to.y
      }
    },
    alpha: 0,
    duration: 500,
    ease: `Sine.easeOut`,
    onComplete: () => {
      text.destroy();
    }
  });
};
