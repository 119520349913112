import Scenery from '../world/scenery';
import Player from '../entities/player';
import { initKeys } from '../input/playerControls';
import { resetGrid } from '../world/grid';
import { respawnWave } from '../world/waves';
import { createFloatyText } from '../../helpers/interface';
import { fnThrottle } from '../../helpers/functions';

import { entityTypeData, baseEntitySize, gameSettings } from '../../settings';

export default ({
  sceneKey,
  onObstacleCollision = (obstacleType) => {},
  onCollectiblePickup = (key) => {},
  onSceneUpdate = () => {}
}) => {
  /* Contexts */
  let gameContext = null;
  let gameControls = null;

  /* Audio */
  let gameMusic = null;

  /* States */
  let gameRunning = false;
  let player = {};
  let playerStart = { x: 0, y: 0 };
  let screenCenterX = 0;
  let screenCenterY = 0;

  /* Velocity of game */
  const velocity = -gameSettings.gameSpeed;

  /* Throttle updater */
  const throttledUpdate = fnThrottle();

  /* Audio functions */
  const playSound = (key) => gameContext.sound.get(key).play();
  const stopSound = (key) => gameContext.sound.get(key).stop();

  /**
   * Recycles or creates entity at position
   * @param {Object} player - the player instance
   * @param {Object} entity - the entity instance you overlapped
   */
  function onEntityOverlap(player, entity) {
    if (player.invincible || player.isDead || entity.active === false) {
      return;
    }

    const { type, key } = entity.optionalData;

    if (type === `obstacle`) {
      onObstacleCollision(key);
      playSound(`sound13`);
      player.kill();
      this._sceneEntities.wipe();
    } else if (type === `collectible`) {
      onCollectiblePickup(key);
      playSound(`sound9`);

      const { score } = entityTypeData.find((entity) => entity.key === key);

      createFloatyText(this, score, {
        transition: {
          from: {
            x: entity.x,
            y: entity.y - baseEntitySize
          },
          to: {
            x: entity.x,
            y: entity.y - baseEntitySize * 2
          }
        },
        fontFamily: `Pixeled, sans-serif`,
        fontSize: `${10}px`
      });

      this._sceneEntities.bin(entity);
    }
  }

  /**
   * Calls players respawn if there are lives left (handled outside of scene)
   */
  function respawn() {
    this.time.delayedCall(
      1000,
      () => {
        player.revive(playerStart.x, playerStart.y);
        const { spawnAtPosition, sceneEntities } = resetGrid(this);
        this._cachedPosition = spawnAtPosition;
        this._sceneEntities = sceneEntities;

        /**
         * Set up player collisions
         */
        const scene = this;
        this.physics.add.overlap(
          player,
          this._sceneEntities.group,
          (playerInstance, entityInstance) => {
            onEntityOverlap.call(scene, playerInstance, entityInstance);
          }
        );
      },
      [],
      this
    );
  }

  /**
   * Runs at scene creation time, called by Phaser internally
   */
  function create() {
    /**
     * Some anon functions need the scene and game context
     */
    gameContext = this.game;

    /**
     * Set up some initial values / resets
     */
    screenCenterX = this.cameras.main.worldView.x + this.cameras.main.width / 2;
    screenCenterY =
      this.cameras.main.worldView.y + this.cameras.main.height / 2;

    playerStart = {
      x: screenCenterX / 2,
      y: screenCenterY
    };

    /**
     * Prepare Sounds
     */
    gameMusic = this.sound.add(`music`);
    gameMusic.setLoop(true);

    this.sound.add(`sound14`);
    this.sound.add(`sound13`);
    this.sound.add(`sound9`);

    // eslint-disable-next-line new-cap
    this.sceneryContext = Scenery({
      gameContext: this
    });

    /**
     * Instantiate the player sprite (and its dead version)
     */
    player = new Player(this, playerStart.x, playerStart.y);

    /**
     * Bootstrap user input events
     */
    gameControls = initKeys(this, player);

    /* Must only fire if game is in running state */
    if (gameRunning === true) {
      const { spawnAtPosition, sceneEntities } = resetGrid(this, player);
      this._cachedPosition = spawnAtPosition;
      this._sceneEntities = sceneEntities;

      /**
       * Set up player collisions
       */
      const scene = this;
      this.physics.add.overlap(
        player,
        this._sceneEntities.group,
        (playerInstance, entityInstance) => {
          onEntityOverlap.call(scene, playerInstance, entityInstance);
        }
      );
    }

    /* Add a rectangle for the player to stand on */
    const platform = this.add.rectangle(
      0,
      gameSettings.screenSize.height - 2,
      gameSettings.screenSize.width,
      10
    );

    this.physics.add.existing(platform);
    platform.body.setAllowGravity(false);
    platform.body.setImmovable(true);
    this.add.existing(platform);

    this.physics.add.collider(player, platform);

    /* Start the update tick increment value */
    this._increment = gameSettings.incrementalUpdateStep;
  }

  /**
   * Runs at scene update time, called by Phaser internally
   * @param {number} time - current loop time
   * @param {number} delta - current delta time since last frame
   */
  function update(time, delta) {
    const deltaWithFPS = delta / (1000 / gameSettings.FPS); // 1000 ms / 60fps
    this._increment = this._increment + 2 * deltaWithFPS; // 2 pixels, adjusted for time delta, don't change this

    // increment of 2 pixels completed, move snake and fox one tile
    if (this._increment > gameSettings.incrementalUpdateStep) {
      this._increment -= gameSettings.incrementalUpdateStep;
      if (!gameRunning || player.isDead) {
        return;
      }

      /** Create velocity value relative to deltaWithFPS */
      const relativeVelocity = gameSettings.gameSpeed * deltaWithFPS;

      /**
       * Update the player
       */
      player.update();

      /**
       * Update the scenery position
       */
      this.sceneryContext.update(-relativeVelocity / 2);

      if (!this._sceneEntities) {
        throttledUpdate.exec(onSceneUpdate);
        return;
      }

      /* Update entities */
      const currentEntities = this._sceneEntities.getEntities();

      let allowSpawn = false;

      currentEntities.forEach((entity) => {
        if (entity.active === false) {
          return;
        }

        if (entity.x < 0) {
          this._sceneEntities.bin(entity);

          if (allowSpawn === false && entity.isLatent === false) {
            allowSpawn = true;
          }
        } else {
          entity.x += -relativeVelocity;
        }
      }, this);

      if (allowSpawn === true) {
        allowSpawn = false;
        respawnWave(this._sceneEntities, this._cachedPosition);
      }

      /**
       * Calls on scene update event, throttled for performance reasons
       */
      throttledUpdate.exec(onSceneUpdate);
    }
  }

  return {
    key: sceneKey,
    gameIsRunning: () => gameRunning,
    create,
    update,
    pause: (state) => {
      gameRunning = state;
    },
    start: () => {
      gameRunning = true;
      playSound(`music`);
    },
    end: () => {
      gameControls.remove();
      gameRunning = false;
      stopSound(`music`);
      playSound(`sound14`);
    },
    respawn,
    jumpSignal: () => {
      gameControls.jumpSignal();
    },
    slideSignal: () => {
      gameControls.slideSignal();
    },
    toggleSound: (state) => {
      if (gameContext !== null) {
        gameContext.sound.setMute(!state);
      }
    },
    /* Debug only */
    getDebugMetrics: function () {
      return {
        currentSpeed: velocity
      };
    },
    toggleDebug: function (state) {
      this.physics.world.drawDebug = state;

      if (!state) {
        this.physics.world.debugGraphic.clear();
      }
    },
    playerIsInvincible: () => player.invincible,
    togglePlayerInvincible: (state) => {
      player.invincible = state;
    }
  };
};
