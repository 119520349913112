import React from 'react';

export const LargeButton = ({
  children = [],
  shadow = true,
  theme = `default`,
  className = ``,
  disabled = false,
  pixelScale = 3, // TODO: Integrate in to classNames
  onClick = () => {},
  ...props
}) => {
  const wrapperBaseTheme = `w-full self-end pixel-button before:bg-black before:top-[9px] before:left-[9px] after:bg-black after:top-[9px] after:left-[0px]`;
  const baseTheme = `z-10 rounded px-4 m-[3px] leading-8 pixel-button transition-all `;

  const themes = {
    [`green`]: `bg-button-green text-white before:bg-button-green after:bg-button-green`,
    [`blue`]: `bg-button-blue text-white before:bg-button-blue after:bg-button-blue hover:bg-button-green hover:before:bg-button-green hover:after:bg-button-green`,
    [`red`]: `bg-button-red text-white before:bg-button-red after:bg-button-red`,
    [`default`]: `bg-default text-black before:bg-default after:bg-default`
  };

  return (
    <div className="flex justify-center basis-[50%] px-6 py-4 sm:px-3 sm:py-2">
      <div
        className={shadow ? `${wrapperBaseTheme}` : ``}
        style={{
          opacity: disabled ? 0.5 : 1
        }}>
        <button
          type="submit"
          disabled={disabled}
          className={`${baseTheme}${
            className.length ? `${className}` : `relative`
          } ${themes[theme]} text-white`}
          onClick={onClick}
          {...props}>
          {children}
        </button>
      </div>
    </div>
  );
};

export default LargeButton;
