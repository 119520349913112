import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Heading } from '../elements/Heading';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Button } from '../elements/Button';
import { CollectibleContent } from './CollectibleContent';
import { MinimalButton } from '../elements/MinimalButton';
import DisclaimerText from './DisclaimerText';
import { pad } from '../../../helpers/interface';

import {
  incrementEducational,
  incrementReplayed,
  refreshLastPlaythrough
} from '../../../api/api';

import {
  ALERT,
  LOCK,
  FOLDER,
  MAGNIFY,
  SHIELD,
  entityTypeData,
  quizEnabled
} from '../../../settings';

import alertImage from '../../../assets/images/alert.png';
import lockImage from '../../../assets/images/lock.png';
import folderImage from '../../../assets/images/folder.png';
import magnifyImage from '../../../assets/images/magnify.png';
import shieldImage from '../../../assets/images/shield.png';
import closeGraphic from '../../../assets/images/close-button.png';

const images = {
  [ALERT]: alertImage,
  [LOCK]: lockImage,
  [FOLDER]: folderImage,
  [MAGNIFY]: magnifyImage,
  [SHIELD]: shieldImage
};

export const GameOver = ({
  score,
  collectibles = [],
  onRestart = () => {},
  onShowSubmitScorePanel = () => {},
  onShowQuizPanel = () => {},
  onClose = () => {}
}) => {
  const [activePanel, setActivePanel] = useState(null);
  const [userScore] = useState(pad(score, 8));
  const [activeDisclaimer, setActiveDisclaimer] = useState(false);

  const handleOnDisclaimerOpen = () => setActiveDisclaimer(true);
  const handleOnDisclaimerClose = () => setActiveDisclaimer(false);

  const handleOnPanelOpen = (key) => {
    setActivePanel(key);
    incrementEducational();
  };

  const handleOnPanelClose = () => setActivePanel(null);

  const handleOnRestart = useCallback(() => {
    onRestart();
    incrementReplayed();
  }, [onRestart]);

  useEffect(() => {
    refreshLastPlaythrough();
  }, []);

  return (
    <Content expand className="relative z-50">
      <Panel>
        <Heading className="pb-2 lg:pb-8">Keep Trying!</Heading>
        <div className="absolute top-7 right-1 lg:top-10 lg:right-10">
          <Button
            className="w-[70%] lg:w-auto"
            shadow={false}
            onClick={onClose}>
            <img src={closeGraphic} alt="Close" />
          </Button>
        </div>
        <ReactMarkdown
          className="pb-2 lg:pb-6 font-pixel font-medium text-xs lg:text-base text-application-dark-blue uppercase"
          // eslint-disable-next-line
          children={`Your score: ${userScore}`}
        />
        <ReactMarkdown className="pb-1 lg:pb-2 font-inter font-medium text-xs lg:text-base">
          Collect all five tools to help the AXIS Cyber Squad get cyber ready.
        </ReactMarkdown>
        <ReactMarkdown className="pb-1 lg:pb-6 font-inter font-medium text-xs lg:text-base">
          Click the tools to find out how AXIS Safe Room helps your customers.
        </ReactMarkdown>
        <div className="m-auto flex items-start justify-evenly relative z-10">
          {collectibles.map((item) => {
            const { meta } = entityTypeData.find(
              (entity) => entity.key === item.key
            );
            return (
              <button
                key={`collectible-${item.key}`}
                type="submit"
                className="w-[20%] mx-0 lg:mx-6 cursor-pointer text-center"
                onClick={() => handleOnPanelOpen(item.key)}>
                <div className="min-h-[1rem] md:min-h-[5rem] flex items-start">
                  <img
                    src={images[item.key]}
                    alt={meta.name}
                    className="min-w-[2rem] md:min-w-[4rem] pixel-img m-auto"
                    style={{
                      opacity: item.collected ? 1 : 0.3
                    }}
                  />
                </div>
                <ReactMarkdown className="font-inter font-medium text-xs lg:text-base">
                  {meta.shortDesc}
                </ReactMarkdown>
              </button>
            );
          })}
        </div>
        <div className="flex justify-center mt-2 lg:mt-12">
          <Button
            className="self-end text-xs lg:text-base lg:leading-8"
            theme="green"
            onClick={handleOnRestart}>
            Replay
          </Button>
          {quizEnabled === true ? (
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              theme="blue"
              onClick={onShowQuizPanel}>
              Boost your score
            </Button>
          ) : (
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              theme="blue"
              onClick={onShowSubmitScorePanel}>
              Submit Score
            </Button>
          )}
        </div>
      </Panel>
      {activePanel && (
        <CollectibleContent
          className="mx-2"
          id={activePanel}
          onClose={handleOnPanelClose}
        />
      )}
      <div className="absolute bottom-0 left-0 w-full text-center px-20 pb-4 lg:pb-8">
        <MinimalButton
          className="inline-block 2xl:hidden py-1 lg:py-3 text-xs font-medium relative z-[100]"
          onClick={handleOnDisclaimerOpen}>
          Disclaimer
        </MinimalButton>
        <DisclaimerText className="pt-6 text-xs hidden 2xl:inline-block relative z-10" />
      </div>
      {activeDisclaimer && (
        <Content expand className="absolute top-0 left-0 z-50">
          <Panel>
            <div className="absolute top-7 right-1 lg:top-10 lg:right-10 z-100">
              <Button
                className="w-[70%] lg:w-auto clickon"
                shadow={false}
                onClick={handleOnDisclaimerClose}>
                <img src={closeGraphic} alt="Close" />
              </Button>
            </div>
            <DisclaimerText className="mx-12 pt-6 text-xs relative z-10" />
          </Panel>
        </Content>
      )}
    </Content>
  );
};

export default GameOver;
