import React, { useCallback, useEffect, useState } from 'react';
import { Heading } from '../elements/Heading';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Button } from '../elements/Button';
import { gameSettings } from '../../../settings';
import { OverflowContent } from '../elements/OverflowContent';

export const Start = ({ onGameStart = () => {} }) => {
  const [readTerms, setReadTerms] = useState(false);

  const handleOnTermsAgreed = useCallback(() => {
    setReadTerms(true);
  }, []);

  const handleOnGameStart = useCallback(() => {
    onGameStart();
  }, [onGameStart]);

  useEffect(() => {
    const onKeyPressed = ({ code }) => {
      if (readTerms === true && code === gameSettings.startGameKey) {
        handleOnGameStart();
      }
    };

    window.addEventListener(`keydown`, onKeyPressed, { passive: true });

    return () => {
      window.removeEventListener(`keydown`, onKeyPressed);
    };
  }, [handleOnGameStart, readTerms]);

  return readTerms === false ? (
    <Content
      tintBackground={false}
      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[70%]">
      <Panel className="px-0 flex flex-col">
        <Heading className="pb-2 text-xl" pixelFont={false}>
          Terms &amp; Conditions
        </Heading>
        <OverflowContent>
          <ul>
            <li className="pb-4">
              This Cyber Awareness Month Game (‘the Game’) is being run by AXIS
              UK Services Limited (‘AXIS’) with registered office address at 52
              Lime Street, London EC3M 7AF, United Kingdom. AXIS is a company
              registered in England and Wales with registered number 05745749.
            </li>
            <li className="pb-4">
              Promotion Period: 1st October 2023 00:01 GMT until 12th November
              2023 23:59 GMT.
            </li>
            <li className="pb-4">
              By playing the Game, you will be deemed to have agreed to be bound
              by these Rules.
            </li>
            <li className="pb-4">
              For each entry submitted during the Promotion Period, AXIS will
              make a donation of 1 GBP to The Cyber Helpline (Charity number:
              1198335) up to a total maximum donation of 6,500 GBP. Entries
              submitted once the total maximum donation amount has been exceeded
              will not increase this donation amount.
            </li>
            <li className="pb-4">
              You must click the ‘Submit Score’ button in order for your entry
              to count towards donation.
            </li>
            <li className="pb-4">
              By submitting your score for the Game, you agree to AXIS
              processing your player ID and score. To understand more about how
              we handle data, please{` `}
              <a
                href="https://www.axiscapital.com/who-we-are/privacy-notice"
                target="_blank"
                rel="noreferrer">
                click here
              </a>
              {` `}
              to see the AXIS Privacy Notice.
            </li>
            <li className="pb-4">
              AXIS reserves the right to cancel, modify or suspend the Game at
              any time.
            </li>
            <li className="pb-4">
              These Rules are governed by English law and their interpretation
              and application will be subject to the exclusive jurisdiction of
              England and Wales.
            </li>
          </ul>
        </OverflowContent>
        <Button
          shadow={false}
          className="self-end text-xs lg:text-base lg:leading-8 mt-8"
          theme="green"
          onClick={handleOnTermsAgreed}>
          OK
        </Button>
      </Panel>
    </Content>
  ) : (
    <Content
      tintBackground={false}
      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <Panel>
        <Heading className="pb-2">Ready?</Heading>
        <Button shadow={false} onClick={handleOnGameStart}>
          Tap To Begin
        </Button>
      </Panel>
    </Content>
  );
};

export default Start;
