/**
 * Object literal to store game settings.
 * @member {Object} */
export const gameSettings = {
  /* General */
  maxLives: 3,
  parentDivId: `gameRoot`,
  startGameKey: `Space`,
  /* Visual */
  backgroundColour: `#222`,
  screenSize: {
    width: 214,
    height: 104
  },
  /* Physics & Control */
  gravity: 600,
  playerJumpStrength: 186,
  gameSpeed: 0.6,
  FPS: 256,
  incrementalUpdateStep: 1
};

/**
 * Object literal to store game scene keys.
 * @member {Object} */
export const sceneKeys = {
  boot: `boot`,
  main: `main`
};

/**
 * Object literal to store game random spawn weights.
 * @member {Object} */
export const weights = {
  chanceOfCollectible: 0.2,
  chanceOfObstacle: 1,
  backgroundPanels: [
    {
      key: `frame_0`,
      weight: 0.1
    },
    {
      key: `frame_1`,
      weight: 0.1
    },
    {
      key: `frame_2`,
      weight: 0.1
    },
    {
      key: `frame_3`,
      weight: 0.1
    },
    {
      key: `frame_4`,
      weight: 0.1
    },
    {
      key: `frame_5`,
      weight: 0.1
    },
    {
      key: `frame_6`,
      weight: 0.1
    },
    {
      key: `frame_7`,
      weight: 0.1
    },
    {
      key: `frame_8`,
      weight: 0.1
    },
    {
      key: `frame_9`,
      weight: 0.1
    }
  ]
};

/**
 * Key constants for each entity type */
export const OBSTACLE = `obstacle`;
export const OBSTACLES = `obstacles`;
export const COLLECTIBLE = `collectible`;
export const COLLECTIBLES = `collectibles`;
export const PLAYER = `player`;
export const ALERT = `alert`;
export const LOCK = `lock`;
export const FOLDER = `folder`;
export const MAGNIFY = `magnify`;
export const SHIELD = `shield`;
export const YELLOW = `yellow`;
export const ORANGE = `orange`;
export const PURPLE = `purple`;
export const GREEN = `green`;

/**
 * Key constants for animations */
export const KO = `ko`;
export const IDLE = `idle`;
export const RUN = `run`;
export const JUMP = `jump`;
export const FALL = `fall`;
export const SLIDE = `slide`;

/**
 * Object literal to store entity types, keys and scores
 * @member {Object} */
export const entityTypeData = [
  {
    key: ALERT,
    type: COLLECTIBLE,
    score: 100,
    meta: {
      name: `Alert`,
      shortDesc: `Off-Site Backup`,
      desc: `Secure off-site backups allow businesses to recover systems and files quickly after a cyber incident. Encryption, malware scanning, and other security methods can keep these backups protected. For best practice, customers should always follow a 3-2-1 method:
* 3 backups
* 2 different mediums
* 1 off-site, encrypted and protected with Multi-Factor Authentication
      `
    }
  },
  {
    key: LOCK,
    type: COLLECTIBLE,
    score: 100,
    meta: {
      name: `Lock`,
      shortDesc: `Multi-Factor Authentication`,
      desc: `Usernames and passwords can be easily stolen or even guessed. Multi-Factor Authentication (MFA) offers a more secure way of confirming identity. Requiring at least two unique “factors” to prove who you are, such as a one-time code, fingerprints, or even physical fobs. MFA should always be used to access privileged accounts or critical systems like servers and industrial control systems.`
    }
  },
  {
    key: FOLDER,
    type: COLLECTIBLE,
    score: 100,
    meta: {
      name: `Folder`,
      shortDesc: `Vulnerability Scanning and Patching`,
      desc: `Threat actors often use vulnerabilities in technology to breach systems, with new weaknesses discovered every day. Regularly scanning for hardware and software with missing or out-of-date patches helps spot exploits before it’s too late. Customers should always patch any vulnerabilities, starting with the most critical threat.`
    }
  },
  {
    key: MAGNIFY,
    type: COLLECTIBLE,
    score: 100,
    meta: {
      name: `Magnifying Glass`,
      shortDesc: `Endpoint Detection and Response`,
      desc: `Endpoint Detention and Response (EDR) tools offer more protection than simple anti-virus and anti-malware solutions using AI to detect potentially malicious behavior. EDR can raise alerts, automatically quarantine infected systems, or allow more in-depth actions. They also help in forensic investigations and clean-up responses. EDR should be installed across 100% of a customer’s endpoints and servers.`
    }
  },
  {
    key: SHIELD,
    type: COLLECTIBLE,
    score: 100,
    meta: {
      name: `Shield`,
      shortDesc: `Security Awareness Training`,
      desc: `Human error is one of the biggest risks to businesses’ systems. Simply following a link or opening an attachment could give threat actors easy access. Robust security training can ensure all employees can spot methods used by threat actors. Spreading training out across the year and reviewing it regularly can help develop an effective culture of security.`
    }
  },
  {
    key: YELLOW,
    type: OBSTACLE,
    score: 0,
    meta: null
  },
  {
    key: ORANGE,
    type: OBSTACLE,
    score: 0,
    meta: null
  },
  {
    key: PURPLE,
    type: OBSTACLE,
    score: 0,
    meta: null
  },
  {
    key: GREEN,
    type: OBSTACLE,
    score: 0,
    meta: null
  }
];

/**
 * Initial state for collectibles gained
 */
export const initialCollectibles = [
  {
    key: LOCK,
    collected: false
  },
  {
    key: ALERT,
    collected: false
  },
  {
    key: FOLDER,
    collected: false
  },
  {
    key: MAGNIFY,
    collected: false
  },
  {
    key: SHIELD,
    collected: false
  }
];

/**
 * Game internal settings
 */
export const baseEntitySize = 14;
export const backgroundPanelWidth = 98;
export const panelPadding = 3;
export const maxPanelCount = 3;
export const totalPanels = panelPadding * 2 + maxPanelCount;

/** Location values
 * 0 = Nothing - Will never show anything
 * 1 = Enemy - Will possibly create an enemy here
 * 2 = Collectible - Will possible create a collectible here
 * */
export const spawnPatterns = [
  [1, 2, 2, 2, 1],
  [2, 2, 0, 1, 0],
  [2, 2, 1, 2, 2],
  [0, 1, 2, 2, 2],
  [1, 0, 2, 2, 2],
  [1, 1, 2, 2, 2],
  [1, 2, 2, 2, 1],
  [0, 2, 0, 1, 1],
  [1, 0, 2, 1, 2],
  [0, 1, 0, 1, 2],
  [2, 2, 1, 1, 2]
];

export const specialPatterns = [
  {
    type: `diagonal`,
    xPositions: [1, 2]
  }
];

/** The score amount obtained by a correct quiz answer */
export const baseQuizScore = 100;

/** Enables the quiz for certain events */
export const quizEnabled = true;

/**
 * Object literal to store quiz global content
 * @member {Object} */
export const quizGlobalContent = {
  correctHeading: `That's correct!`,
  incorrectHeading: `That's incorrect.`
};

/**
 * Object literal to store quiz questions
 * @member {Object} */
export const quizQuestions = [
  {
    id: `0`,
    question: `Using two instances of a username and password is a good form of multi-factor authentication.`,
    summary: `MFA requires the use of two separate factors for more secure authentication. Using two usernames and passwords is simply two instances of a single factor which would not meet the MFA threshold.`,
    expected: 1,
    answers: [`Yes`, `No`]
  },
  {
    id: `1`,
    question: `The best practice methodology for back-ups is described by which of the below:`,
    summary: ``,
    expected: 2,
    answers: [`A-B-C`, `1-2-3`, `3-2-1`]
  },
  {
    id: `2`,
    question: `Patching of vulnerabilities should begin with the least critical vulnerabilities to allow more time for testing of the more critical ones. `,
    summary: `Vulnerabilities should be patched based on the highest criticality first.`,
    expected: 1,
    answers: [`Yes`, `No`]
  },
  {
    id: `3`,
    question: `Which of the below is something Endpoint Detection and Response is NOT designed to do?`,
    summary: `Any type of malware can be used by various threat actors or threat actor organizations.`,
    expected: 3,
    answers: [
      `Raise alerts`,
      `Auto quarantine systems`,
      `Allow greater data collection for forensic investigations`,
      `Identify threat actors based on type of malware identified`
    ]
  },
  {
    id: `4`,
    question: `One of the biggest issues that awareness training is designed to address is:`,
    summary: ``,
    expected: 1,
    answers: [
      `A: Incomplete technical knowledge`,
      `B: Human Error`,
      `C: New threats`,
      `D: Detailed threat actor techniques`
    ]
  },
  {
    id: `5`,
    question: `Spreading security training out over the course of a year, and doing things like sending regular and periodic updates, gamification such as offering prizes for reporting phishing messages and similar activities is normally called creating a:`,
    summary: ``,
    expected: 2,
    answers: [
      `A: Nuanced security program`,
      `B: Enhanced security program`,
      `C: Culture of Security`,
      `D: Culture of Training`
    ]
  },
  {
    id: `6`,
    question: `What does APT stand for?`,
    summary: `Advanced persistent threat – a group with the capabilities, resources and intent to effectively and persistently target a specific entity`,
    expected: 1,
    answers: [
      `A: Advanced payment threat`,
      `B: Advanced persistent threat`,
      `C: Advanced privacy threat`
    ]
  },
  {
    id: `7`,
    question: `What is usually the minimum number of days a business should be able to recover all critical data and systems?`,
    summary: ``,
    expected: 0,
    answers: [`A: 10`, `B: 15`, `C: 20`]
  },
  {
    id: `8`,
    question: `What is the best defense against social engineering?`,
    summary: `All of these are good strategies, but training cyber security awareness amongst employees is the best defense via e.g. simulated phishing campaigns and teaching people not to click on dodgy emails. The aim here is to create a “human firewall” of educated staff that mirrors the system firewall but in the psychological domain.`,
    expected: 0,
    answers: [
      `Staff training`,
      `Multi-Factor Authentication`,
      `Anti-virus software`
    ]
  },
  {
    id: `9`,
    question: `What is a white hat?`,
    summary: `A criminal security hacker is known as Black Hat and a personal revenge hacker is known as a Red Hat`,
    expected: 0,
    answers: [
      `An ethical security hacker`,
      `A criminal security hacker`,
      `A personal revenge hacker`
    ]
  },
  {
    id: `10`,
    isHTML: true,
    question: `What is a tabletop exercise?`,
    summary: `AXIS offers a variety of cyber tabletop exercises to our customers. These range from technical ones from external third parties to our own Cyber Incident Game which is part of the AXIS Understanding Cyber Security and Insurance (UCSI) course. <a href="https://www.axiscapital.com/insurance/cyber-technology-e-o/axis-cyber-services/prepare?banner_video=foo" target="_blank">Watch the UCSI video to learn more.</a> <a href="https://www.axiscapital.com/insurance/cyber-technology-e-o/axis-cyber-services/prepare?banner_video=foo" target="_blank">Watch the Cyber Incident Simulation video to learn more.</a>`,
    expected: 2,
    answers: [
      `A type of yoga you can do in your kitchen`,
      `The mystery behind the left-over pieces after you have assembled IKEA furniture`,
      `A simulated cyber incident for training purposes`
    ]
  },
  {
    id: `11`,
    isHTML: true,
    question: `What does a pot of honey have to do with cyber insurance?`,
    summary: `A “honeypot” is a fake target created in a computer system designed to attract hackers, in the same way that a pot of honey attracts flies. This lets the defenders observe the attacker’s techniques and so learn how to improve defences. <a href="https://bit.ly/43WLQDZ" target="_blank">Click here to learn more.</a>`,
    expected: 1,
    answers: [
      `It’s created by a team of busy people working hard - like bees`,
      `A honeypot is a type of cyber defence strategy`,
      `It’s a very sweet experience`
    ]
  },
  {
    id: `12`,
    isHTML: true,
    question: `What does a finch have to do with cyber insurance?`,
    summary: `Cyber criminals are commonly portrayed in images as a single menacing figure, wearing a hoodie in a basement, crouching over a laptop. In reality, the dark web is a whole interconnected network of specialists and experts, creating full supply chains of subcontractors and intermediaries. This is much like the division of labour in a modern economy, or the speciation in an ecosystem first observed by Darwin with his finches in the Galapagos Islands. <a href="https://bit.ly/3QrUs2c" target="_blank">Click here to learn more.</a>`,
    expected: 0,
    answers: [
      `The dark web is speciated, just like Darwin’s finches`,
      `Cyber insurance originated in Finch Lane, off Threadneedle Street in the City of London`,
      `“Livid Finch” is the cyber gang behind some recent high profile ransomware attacks.`
    ]
  },
  {
    id: `13`,
    question: ` What does the concept of “least privilege” mean in practice?`,
    summary: `The concept of least privilege is a guiding principle behind setting authorisation protocols. When deciding who should have access to a company’s’ different systems or databases, the answer should be only those who have a clear need in the daily course of their work. All others should be blocked. So configuring access to a network on a “least privilege” basis means giving users the minimum set of permissions required to do their job.`,
    expected: 1,
    answers: [
      `Those who are already privileged should get the least help`,
      `Users should have the minimum set of permissions required to do their job`,
      `Users who practice the least will never achieve high rank or privilege`
    ]
  },
  {
    id: `14`,
    question: ` What data is needed when making a claim for business interruption following a cyber event?`,
    summary: `A business interruption claim needs to provide supporting evidence of lost revenues and profits caused by the incident. This requires hourly and daily sales figures from the period before the incident to establish what the historic trends have been. Data is also required for the period of disruption to illustrate what the impact was. However, to demonstrate that sales have been lost and not merely deferred, sales data for the period after the systems have been restored is also required. A big surge in sales after restoration means customers were just waiting to buy. If purchases were only postponed like this, then arguably sales have not been lost.`,
    expected: 3,
    answers: [
      `Hourly and daily sales data for the period of disruption`,
      `Daily sales data for 12 months covering the period before the incident.`,
      `Daily sales data for 12 months after systems have been restored.`,
      `All of the above`
    ]
  }
];
