import { toIntArray, randomString } from '../helpers/interface';
import { getRandomInt } from '../helpers/chance';

/* Set to local mode to skip API calls */
const localMode = process.env.REACT_APP_LOCAL_MODE === `true`;

/**
 * Use for when you just want to test against static data
 * @param {number} maxEntries - Max entries to make
 * @returns {Array} - Array of leaderboard items generated randomly
 */
const getStaticRequest = (maxEntries) => {
  return {
    data: {
      Items: toIntArray(maxEntries).map((item) => {
        return {
          name: randomString(),
          score: getRandomInt(0, 9999)
        };
      })
    }
  };
};

/**
 * Perform request to API server
 * @param {Object} bodyData - Method and payload to send to API server
 * @param {Function} onError - Optional callback if errored
 * @returns {Object} - Returns response object
 */
const performRequest = async ({
  bodyData = {
    command: ``,
    payload: {}
  },
  onError = (response) => {}
}) => {
  const response = await fetch(`https://db-${window.location.hostname}`, {
    method: `POST`,
    body: JSON.stringify(bodyData)
  });

  if (!response.ok) {
    onError(response);
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const jsonResponse = await response.json();
  return jsonResponse;
};

/**
 * Gets leaderboard data from API server
 * @param {number} leaderboardScore - The current user leaderboard score
 * @param {number} max - Optional max number of entries to return
 * @returns {array} - Returns array of items
 */
export const getLeaderboardData = async ({ leaderboardScore = 0, max = 8 }) => {
  const { data } = localMode
    ? getStaticRequest(max)
    : await performRequest({
        bodyData: {
          command: `leaderboardList`,
          payload: {
            leaderboardScore,
            max
          }
        },
        onError: () => {
          console.error(`There as an error getting leaderboard entries.`);
        }
      });

  return data;
};

/**
 * Saves user score after a playthrough
 * @param {Object} scoreData - Score data to submit to the API server for user
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const saveUserScore = async ({ name = ``, score = 0 }) => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `leaderboardPut`,
      payload: {
        leaderboardName: name,
        leaderboardScore: `${score}`,
        leaderboardDate: `${Date.now()}`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Triggers an internal update to set the current highest score
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const refreshHighestScore = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsScore`,
      payload: {
        analyticsId: `highestscore`,
        orderBy: `descending`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Triggers an internal update to record the current lowest score
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const refreshLowestScore = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsScore`,
      payload: {
        analyticsId: `lowestscore`,
        orderBy: `ascending`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Triggers an internal update to update the last playthrough
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const refreshLastPlaythrough = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsLastPlaythrough`,
      payload: {
        analyticsId: `lastplaythrough`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Increments educational interactions
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const incrementEducational = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsIncrement`,
      payload: {
        analyticsId: `educationalinteractions`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Increments number of players so far
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const incrementSubmittedScore = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsIncrement`,
      payload: {
        analyticsId: `submittedscore`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Increments number of initial playthroughs
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const incrementFirstPlaythrough = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsIncrement`,
      payload: {
        analyticsId: `uniqueplayers`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Increments times game was instantly replayed afte game over
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const incrementReplayed = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsIncrement`,
      payload: {
        analyticsId: `replayedinstantly`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Increments times player pressed start game for the first time
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const incrementPressedStartInitial = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsIncrement`,
      payload: {
        analyticsId: `pressedstartgameinitial`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Increments times player pressed start game
 * @returns {boolean} - Returns if request finished (could be erronous or not)
 */
export const incrementPressedStart = async () => {
  if (localMode) {
    return true;
  }

  const { success } = await performRequest({
    bodyData: {
      command: `analyticsIncrement`,
      payload: {
        analyticsId: `pressedstartgame`
      }
    },
    onError: () => {
      console.error(`There was an error saving the user's score.`);
    }
  });

  return success;
};

/**
 * Retrieves all analytics data
 * @returns {Object} - Returns requested data
 */
export const getAnalytics = async () => {
  if (localMode) {
    return { data: { Responses: null } };
  }

  const result = await performRequest({
    bodyData: {
      command: `getAnalytics`,
      payload: {
        queries: [
          `lastplaythrough`,
          `highestscore`,
          `educationalinteractions`,
          `replayedinstantly`,
          `lowestscore`,
          `submittedscore`,
          `uniqueplayers`,
          `pressedstartgameinitial`,
          `pressedstartgame`
        ]
      }
    },
    onError: () => {
      console.error(`There was an error retrieving data.`);
    }
  });

  return result;
};
