import React, { Children, Fragment, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from './Button';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import leftArrowInactive from '../../../assets/images/left-arrow-inactive.png';
import leftArrowActive from '../../../assets/images/left-arrow-active.png';
import rightArrowInactive from '../../../assets/images/right-arrow-inactive.png';
import rightArrowActive from '../../../assets/images/right-arrow-active.png';

export const Carousel = ({ children, onPlayButtonClicked = () => {} }) => {
  const arrayChildren = Children.toArray(children);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideNumber] = useState(arrayChildren.length);

  const handleOnSlideChange = ({ activeIndex }) => setCurrentSlide(activeIndex);

  return (
    <Fragment>
      <Swiper
        className="h-full"
        spaceBetween={50}
        slidesPerView={1}
        modules={[Navigation, Pagination]}
        navigation={{
          prevEl: `.prev`,
          nextEl: `.next`
        }}
        pagination={{
          el: `.pagination`,
          clickable: true,
          type: `bullets`,
          bulletClass: `swiper-pagination-bullet rounded-none`,
          bulletActiveClass: `swiper-pagination-bullet-active bg-blue`
        }}
        onSlideChange={handleOnSlideChange}>
        {arrayChildren.map((child) => {
          return <SwiperSlide key={`slide-${child.key}`}>{child}</SwiperSlide>;
        })}
      </Swiper>
      <button
        type="submit"
        className={`w-3 md:w-auto prev absolute left-4 lg:left-10 top-[50%] z-10 ${
          currentSlide > 0 ? `active` : `inactive`
        }`}>
        <img
          src={currentSlide > 0 ? leftArrowActive : leftArrowInactive}
          alt="Left"
        />
      </button>
      <button
        type="submit"
        className={`w-3 md:w-auto next absolute right-4 lg:right-10 top-[50%] z-10 ${
          currentSlide < slideNumber - 1 ? `active` : `inactive`
        }`}>
        <img
          src={
            currentSlide < slideNumber - 1
              ? rightArrowActive
              : rightArrowInactive
          }
          alt="Right"
        />
      </button>
      <div
        className="pagination z-10"
        style={{
          display: currentSlide < slideNumber - 1 ? `block` : `none`,
          position: `absolute`,
          bottom: `2rem md:4rem`
        }}
      />
      {currentSlide === slideNumber - 1 && (
        <div className="absolute left-[50%] bottom-4 lg:bottom-[4rem] z-10 -translate-x-2/4">
          <Button
            className="text-xs lg:text-base lg:leading-8"
            theme="blue"
            onClick={onPlayButtonClicked}>
            Start Playing
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default Carousel;
