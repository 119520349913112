import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Button } from '../elements/Button';
import {
  ALERT,
  LOCK,
  FOLDER,
  MAGNIFY,
  SHIELD,
  entityTypeData
} from '../../../settings';
import alertImage from '../../../assets/images/alert.png';
import lockImage from '../../../assets/images/lock.png';
import folderImage from '../../../assets/images/folder.png';
import magnifyImage from '../../../assets/images/magnify.png';
import shieldImage from '../../../assets/images/shield.png';
import closeGraphic from '../../../assets/images/close-button.png';

const images = {
  [ALERT]: alertImage,
  [LOCK]: lockImage,
  [FOLDER]: folderImage,
  [MAGNIFY]: magnifyImage,
  [SHIELD]: shieldImage
};

export const CollectibleContent = ({ id, onClose = (key) => {} }) => {
  const { meta } = entityTypeData.find((entity) => entity.key === id);
  return (
    <Content expand className="absolute top-0 left-0 z-50">
      <Panel>
        <div className="absolute top-7 right-1 lg:top-10 lg:right-10">
          <Button
            className="w-[70%] lg:w-auto"
            shadow={false}
            onClick={onClose}>
            <img src={closeGraphic} alt="Close" />
          </Button>
        </div>
        <div className="h-full flex items-center text-left lg:px-16">
          <img
            src={images[id]}
            alt={meta.name}
            className="min-w-[10rem] max-w-10 lg:max-w-none mx-8 pixel-img m-auto"
          />
          <ReactMarkdown
            className="leading-1 lg:leading-8 font-inter font-medium text-xs lg:text-base"
            remarkPlugins={[remarkGfm]}>
            {meta.desc}
          </ReactMarkdown>
        </div>
      </Panel>
    </Content>
  );
};

export default CollectibleContent;
