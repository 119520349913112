import React from 'react';

export const Content = ({
  z = `10`,
  expand = false,
  tintBackground = true,
  edge = false,
  className = ``,
  children = []
}) => {
  const baseClassName = `${
    tintBackground ? ` bg-application-dark-blue/80` : ``
  }`;

  const withExpand = expand ? `${baseClassName} w-full h-full` : baseClassName;

  const withPadding = edge ? `` : `p-5 `;

  return (
    <div
      className={`${withExpand} z-${z}${
        className.length ? ` ${className}` : ``
      }`}>
      <div className={`${withPadding}h-full`}>{children}</div>
    </div>
  );
};

export default Content;
