import React from 'react';

export const SubHeading = ({
  className = `pb-8 text-application-blue `,
  children = []
}) => {
  const baseClass = `text-xl lg:text-4xl lg:leading-[3rem] `;
  return <h4 className={baseClass.concat(className)}>{children}</h4>;
};

export default SubHeading;
