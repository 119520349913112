import React from 'react';
import { Heading } from '../elements/Heading';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';

export const OrientationPrompt = () => {
  return (
    <Content expand className="relative z-50">
      <Panel>
        <Heading>Best In Landscape</Heading>
        <p className="leading-4 text-sm font-pixel">
          Please rotate your device to landscape mode.
        </p>
      </Panel>
    </Content>
  );
};

export default OrientationPrompt;
