import Phaser from 'phaser';
import boot from './scenes/boot';
import main from './scenes/main';
import { gameSettings, sceneKeys } from '../settings';

const bootGame = ({
  onCollectiblePickup = (key) => {},
  onObstacleCollision = (key) => {},
  onSceneUpdate = () => {}
}) => {
  // TODO: much of these settings belong in the config file
  const game = new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: gameSettings.backgroundColour,
    scale: {
      mode: Phaser.Scale.FIT,
      // Specify a string as parent to prevent Phaser ejecting out of container on change
      parent: gameSettings.parentDivId,
      ...gameSettings.screenSize
    },
    render: {
      pixelArt: true
    },
    physics: {
      default: `arcade`,
      arcade: {
        overlapBias: 200,
        fps: gameSettings.FPS * 2,
        gravity: { y: gameSettings.gravity },
        debug: false
      }
    }
  });

  game.scene.add(
    sceneKeys.boot,
    boot({
      sceneKey: sceneKeys.boot,
      loadScene: sceneKeys.main
    }),
    true
  );

  game.scene.add(
    sceneKeys.main,
    main({
      sceneKey: sceneKeys.main,
      onObstacleCollision,
      onCollectiblePickup,
      onSceneUpdate
    }),
    false
  );

  return game;
};

export const createGameObject = () => {
  let gameInstance = null;
  let gameBooted = false;

  return {
    playerInvincible: () => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      return scene.systems.config.playerIsInvincible.call(scene.scene);
    },
    gameRunning: () => {
      if (gameInstance === null) {
        return false;
      }

      const sceneRequest = gameInstance.scene.getScene(sceneKeys.main);

      if (sceneRequest === null) {
        return false;
      }

      const { scene } = sceneRequest;
      return scene.systems.config.gameIsRunning.call(scene.scene);
    },
    gameBooted: () => {
      if (gameInstance === null) {
        return false;
      }

      const sceneRequest = gameInstance.scene.getScene(sceneKeys.main);

      if (sceneRequest === null) {
        return false;
      }

      return gameBooted;
    },
    toggleDebug: (state) => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.toggleDebug.call(scene.scene, state);
    },
    togglePlayerInvincible: (state) => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.togglePlayerInvincible.call(scene.scene, state);
    },
    toggleSound: (state) => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      return scene.systems.config.toggleSound.call(scene.scene, state);
    },
    getDebugMetrics: () => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      return scene.systems.config.getDebugMetrics.call(scene.scene);
    },
    jumpSignal: () => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.jumpSignal();
    },
    slideSignal: () => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.slideSignal();
    },
    pause: (state) => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.pause(state);
    },
    end: (onEndedTimer = () => {}) => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.end();
      scene.scene.time.delayedCall(1000, onEndedTimer, [], scene);
    },
    start: () => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.restart();
      scene.systems.config.start();
    },
    respawn: () => {
      const { scene } = gameInstance.scene.getScene(sceneKeys.main);
      scene.systems.config.respawn.call(scene.scene);
    },
    initialize: ({
      onSceneUpdate = () => {},
      onCollectiblePickup = (key) => {},
      onObstacleCollision = (key) => {}
    }) => {
      if (!gameBooted) {
        gameBooted = true;
        gameInstance = bootGame({
          onCollectiblePickup: (key) => onCollectiblePickup(key),
          onObstacleCollision: (key) => onObstacleCollision(key),
          onSceneUpdate: () => onSceneUpdate()
        });
      } else {
        console.error(`Avoid calling initialize if already boot-locked.`);
      }
    }
  };
};

export default createGameObject;
