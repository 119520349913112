import Phaser from 'phaser';
import {
  getRandomInt,
  pickWeightedRandom,
  makeArray
} from '../../helpers/chance';
import { baseEntitySize } from '../../settings';

/**
 * Recycles or creates entity at position
 * @param {Array} entityData - collection of objects defining sprite key and type
 * @param {Constructor} Constructor - constructor to use for instantiation
 * @returns {Object} - object containing instance group, pool and entities created
 */
export function createEntities(entityData, Constructor) {
  const entityGroup = this.add.group();

  const binEntity = (entity) => {
    entityGroup.killAndHide(entity);
  };

  for (let i = 0; i < entityData.length; i++) {
    const { key, type } = entityData[i];
    const entity = new Constructor(
      this,
      this.cameras.main.centerX * 2 + baseEntitySize,
      0,
      key,
      {
        id: Phaser.Math.RND.uuid(),
        type,
        key
      }
    );

    entityGroup.add(entity);
    entity.setDepth(1);
    entity.play(key);
    entity.isLatent = false;

    binEntity(entity);
  }

  return {
    group: entityGroup,
    wipe: () => {
      entityGroup.getChildren().forEach((entity) => binEntity(entity));
    },
    bin: (entity) => {
      binEntity(entity);
    },
    respawnNextEntity: (x, y, entity, isLatent = false) => {
      if (entity === null || entity === undefined) {
        console.error(
          `Could not respawn entity as none were available in pool.`
        );
        return;
      }

      entity.revive(x, y);
      entity.active = true;
      entity.visible = true;
      entity.isLatent = isLatent;

      return entity;
    },
    getEntities: () => entityGroup.getChildren(),
    getFirstInactiveType: ({ type, shuffleOnSearch = false }) => {
      const shuffled = shuffleOnSearch ? entityGroup.shuffle() : entityGroup;
      const [firstReady] = shuffled
        .getChildren()
        .filter((x) => x.active === false && x.optionalData.type === type);

      return firstReady !== null ? firstReady : null;
    }
  };
}

/**
 * Creates a container of panels to use for the infinite background
 * @param {Object} options - object of specific options
 * @param {Object} sceneContext - the scene that this method was called from
 * @returns {Object} - container housing instantiated sprites
 */
export function createInfiniteBackground(
  { weights, panelWidth, panelCount, panelPadding },
  sceneContext
) {
  /**
   * Create some preset panel padding sprite keys to generate panels either side of random middle panels
   */
  const panelPaddingItems = makeArray(panelPadding).map(() => {
    return `frame_${getRandomInt(0, 1)}`;
  });

  /**
   * Keep a collection to store all panels for the background group
   */
  let panelKeys = [];

  /**
   * Pad out the start
   */
  for (let j = 0; j < panelPaddingItems.length; j++) {
    panelKeys.push(panelPaddingItems[j]);
  }

  /**
   * Populate middle
   */
  for (let i = panelPadding; i < panelCount + panelPadding; i++) {
    panelKeys.push(pickWeightedRandom(weights.backgroundPanels).key);
  }

  /**
   * Pad out the end
   */
  const endPadding = panelCount + panelPadding;
  for (let j = endPadding; j < panelPaddingItems.length + endPadding; j++) {
    panelKeys.push(panelPaddingItems[j - endPadding]);
  }

  /**
   * Then add them to a Phaser group
   */
  const container = sceneContext.add.container();
  panelKeys.forEach((key, i) => {
    const spr = sceneContext.add.sprite(i * panelWidth, 0, key);
    spr.setOrigin(0, 0);
    spr.setDepth(0);

    container.add(spr);

    if (sceneContext.anims.exists(key)) {
      spr.play(key);
    }
  });

  container.setSize(container.length, panelWidth);
  return container;
}

export const rerollInfiniteBackground = (
  { weights, panelWidth, panelCount, panelPadding },
  container,
  sceneContext
) => {
  for (
    let i = panelPadding;
    i < panelPadding + (panelCount - panelPadding);
    i++
  ) {
    const oldPanelSprite = container.getAt(i);
    const { key } = pickWeightedRandom(weights.backgroundPanels);
    const panelSprite = sceneContext.add.sprite(i * panelWidth, 0, key);
    panelSprite.setOrigin(0, 0);
    panelSprite.setDepth(0);
    panelSprite.setPosition(oldPanelSprite.x, oldPanelSprite.y);
    container.replace(oldPanelSprite, panelSprite, true);

    if (sceneContext.anims.exists(key)) {
      panelSprite.play(key);
    }
  }
};

export const rerollBackgroundPadding = (
  { weights, panelWidth, panelCount, panelPadding },
  container,
  sceneContext
) => {
  for (let i = panelPadding; i < panelPadding; i++) {
    const { key } = pickWeightedRandom(weights.backgroundPanels);

    const oldStartPanel = container.getAt(i);
    const newPanelStart = sceneContext.add.sprite(i * panelWidth, 0, key);
    newPanelStart.setOrigin(0, 0);
    newPanelStart.setDepth(0);
    newPanelStart.setPosition(oldStartPanel.x, oldStartPanel.y);
    container.replace(oldStartPanel, newPanelStart, true);

    const oldEndPanel = container.getAt(i + (panelCount - panelPadding));
    const newPanelEnd = sceneContext.add.sprite(i * panelWidth, 0, key);
    newPanelEnd.setOrigin(0, 0);
    newPanelEnd.setDepth(0);
    newPanelEnd.setPosition(oldEndPanel.x, oldEndPanel.y);
    container.replace(oldEndPanel, newPanelEnd, true);

    if (sceneContext.anims.exists(key)) {
      newPanelStart.play(key);
      newPanelEnd.play(key);
    }
  }
};
