import React, { StrictMode, useState, useRef } from 'react';
import {
  entityTypeData,
  gameSettings,
  initialCollectibles
} from '../../settings';
import Wrapper from '../components/compounds/Wrapper';
import { GameProvider } from '../components/compounds/GameProvider';

/**
 * Main application root component and takes care of global data state outside of API.
 *
 * @returns {Component} - Returns react component
 * @component
 * @example
 * return (
 *  <Index />
 * )
 */
const Index = () => {
  const gameRef = useRef(null);

  /**
   * Global game and state data
   */
  const [name, setName] = useState(``);
  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(gameSettings.maxLives);
  const [collectibles, setCollectibles] = useState([...initialCollectibles]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [firstPlaythrough, setFirstPlaythrough] = useState(true);

  /* Game event handlers */
  const handleOnSceneUpdate = () => {
    setScore((currentScore) => Math.round(currentScore) + 1);
  };

  const handleOnObstacleCollision = () => {
    setLives((prevLives) => (prevLives > 0 ? prevLives - 1 : 0));
  };

  const handleOnCollectiblePickup = (key) => {
    const collectible = entityTypeData.find((entity) => entity.key === key);
    setScore((currentScore) => Math.round(currentScore) + collectible.score);
    setCollectibles((current) => {
      return current.map((x) => {
        if (x.key === key) {
          return {
            ...x,
            collected: true
          };
        }

        return x;
      });
    });
  };

  /* Wrapper event handlers */
  const handleSubmitUserData = (userName) => setName(userName);

  const handleOnGameRestart = () => {
    setFirstPlaythrough(false);
    setIsGameOver(false);
    setLives(gameSettings.maxLives);
    setScore(0);
  };

  return (
    <StrictMode>
      <GameProvider
        gameRef={gameRef}
        onSceneUpdate={handleOnSceneUpdate}
        onCollectiblePickup={handleOnCollectiblePickup}
        onObstacleCollision={handleOnObstacleCollision}>
        <Wrapper
          name={name}
          score={score}
          lives={lives}
          collectibles={collectibles}
          isGameOver={isGameOver}
          firstPlaythrough={firstPlaythrough}
          onSubmitUserData={handleSubmitUserData}
          onGameRestart={handleOnGameRestart}>
          <div
            ref={gameRef}
            id={gameSettings.parentDivId}
            className="sm:flex sm:justify-center sm:items-center bg-application-dark-blue relative z-[1]"
          />
        </Wrapper>
      </GameProvider>
    </StrictMode>
  );
};

export default Index;
