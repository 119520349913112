import { fnDiv, fnRound } from '../../helpers/functions';

import {
  weights,
  maxPanelCount,
  totalPanels,
  panelPadding,
  backgroundPanelWidth
} from '../../settings';

import {
  createInfiniteBackground,
  rerollInfiniteBackground,
  rerollBackgroundPadding
} from '../factories/entityFactory';

export const Scenery = ({ gameContext }) => {
  let backgroundCooldown = false;

  /**
   * Creates the container and sprites for infinitely scrolling background
   */
  let backgroundContainer = createInfiniteBackground(
    {
      weights,
      panelWidth: backgroundPanelWidth,
      panelCount: maxPanelCount,
      panelPadding
    },
    gameContext
  );

  return {
    update: (velocity) => {
      /**
       * Handles moving the background sprites and recycles when off-screeen
       */
      backgroundContainer.x += velocity;

      if (
        backgroundContainer.x +
          totalPanels * backgroundPanelWidth -
          panelPadding * backgroundPanelWidth <=
        0
      ) {
        rerollInfiniteBackground(
          {
            weights,
            panelWidth: backgroundPanelWidth,
            panelCount: maxPanelCount,
            panelPadding
          },
          backgroundContainer,
          gameContext
        );

        backgroundContainer.setPosition(0, backgroundContainer.y);
        backgroundCooldown = false;
      }

      /**
       * Trigger mid point between background panels
       */
      const bgPosition = fnRound(backgroundContainer.x);

      if (
        !backgroundCooldown &&
        bgPosition === fnDiv(backgroundContainer.width, 2)
      ) {
        rerollBackgroundPadding(
          {
            weights: weights.backgroundPanels,
            panelWidth: backgroundPanelWidth,
            panelCount: totalPanels,
            panelPadding
          },
          backgroundContainer,
          gameContext
        );
        backgroundCooldown = true;
      }
    }
  };
};

export default Scenery;
