import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from '../elements/Button';
import introBannerPrimaryGraphic from '../../../assets/images/intro-banner-primary.png';
import leftArrowBright from '../../../assets/images/left-arrow-bright.png';

export const IntroBanner = ({
  isDesktop = true,
  onStart = () => {},
  onTutorial = () => {},
  onLeaderboard = () => {}
}) => {
  return (
    <div className="bg-blue h-full overflow-hidden relative z-[100] text-center intro-banner">
      <div className="w-full h-full flex flex-col text-center">
        {isDesktop ? (
          <img
            src={introBannerPrimaryGraphic}
            alt="AXIS"
            className="self-center absolute z-10"
          />
        ) : (
          <img
            src={introBannerPrimaryGraphic}
            alt="AXIS"
            className="self-center responsive-img absolute z-10"
          />
        )}
        <div className="self-end mt-auto relative z-20">
          <ReactMarkdown className="text-white text-xs md:text-sm lg:text-base font-inter px-6 md:px-8 lg:px-16 xl:px-32">
            The AXIS Cyber Squad has been called into action. Can you help them
            prepare your customers for a cyber incident? Race through the AXIS
            Safe Room and unlock the secrets to cyber resilience by collecting
            security tools to boost your score. But don't let the viruses ruin
            your plans!
          </ReactMarkdown>
          <div className="flex w-full justify-center py-3 md:py-4 lg:py-6 xl:py-8">
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              theme="green"
              onClick={onStart}>
              Start
            </Button>
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              theme="green"
              onClick={onTutorial}>
              How To Play
            </Button>
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              onClick={onLeaderboard}>
              Leaderboard
            </Button>
          </div>
        </div>
      </div>

      <div className="absolute top-10 left-10">
        <a
          href="https://www.axiscapital.com/insurance/cyber-technology-e-o/cyber-squad"
          title="Return to AXIS Capital"
          className="block w-14 hover:ml-[-.5em] transition-all">
          <img src={leftArrowBright} alt="Return to AXIS Capital" />
        </a>
      </div>
    </div>
  );
};

export default IntroBanner;
