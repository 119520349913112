import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toIntArray } from '../../../helpers/interface';
import { MinimalButton } from './MinimalButton';
import rightArrowActive from '../../../assets/images/right-arrow-active.png';

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = [
  ...alpha.map((x) => String.fromCharCode(x)),
  ...toIntArray(10).map((int) => `${int}`)
];

export const NameEntry = ({
  maxCharacters = 5,
  isDesktop = true,
  onNameEntry = (str) => {}
}) => {
  const itemsArray = toIntArray(maxCharacters);
  const itemsRef = useRef([]);
  const [currentSlot, setCurrentSlot] = useState(-1);
  const [characterIndex, setCharacterIndex] = useState(0);

  const handleKeyUp = useCallback(
    (i, key, value) => {
      if (
        !isDesktop ||
        !alphabet.some((char) => char.toLowerCase() === key.toLowerCase())
      ) {
        return;
      }

      setCurrentSlot(i);

      const currentEmpty = itemsRef.current[i].value.length === 0;

      if (currentEmpty && i > 0 && (key === `Backspace` || key === `Delete`)) {
        itemsRef.current[i - 1].focus();
      }

      if (value.length === 1 && i < itemsArray.length - 1) {
        itemsRef.current[i + 1].focus();
        itemsRef.current[i + 1].value = ``;
      }

      if (key === `Backspace` || key === `Delete`) {
        onNameEntry(``, i);
      } else {
        onNameEntry(key, i);
      }
    },
    [isDesktop, itemsArray.length, onNameEntry]
  );

  const handleButtonUp = useCallback(
    (slotIndex) => {
      if (currentSlot !== slotIndex) {
        itemsRef.current[slotIndex].focus();
        setCurrentSlot(slotIndex);

        const currentValue = itemsRef.current[slotIndex].value;
        const indexOfValue =
          currentValue.length > 0 ? alphabet.indexOf(currentValue) : 0;

        const prevcharacter =
          indexOfValue - 1 < 0 ? alphabet.length - 1 : indexOfValue - 1;

        itemsRef.current[slotIndex].value = alphabet[prevcharacter];

        setCharacterIndex(prevcharacter);
      } else {
        const index =
          characterIndex === 0 ? alphabet.length - 1 : characterIndex - 1;
        itemsRef.current[slotIndex].value = alphabet[index];

        setCharacterIndex(index);
      }

      onNameEntry(itemsRef.current[slotIndex].value, slotIndex);
    },
    [characterIndex, currentSlot, onNameEntry]
  );

  const handleButtonDown = useCallback(
    (slotIndex) => {
      if (currentSlot !== slotIndex) {
        itemsRef.current[slotIndex].focus();
        setCurrentSlot(slotIndex);

        const currentValue = itemsRef.current[slotIndex].value;
        const initialValue = currentValue.length > 0 ? 1 : 0;
        const indexOfValue =
          currentValue.length > 0 ? alphabet.indexOf(currentValue) : 0;

        const nextCharacter =
          indexOfValue + 1 > alphabet.length - 1
            ? 0
            : indexOfValue + initialValue;

        itemsRef.current[slotIndex].value = alphabet[nextCharacter];

        setCharacterIndex(nextCharacter);
      } else {
        const currentValue = itemsRef.current[slotIndex].value;
        const initialValue = currentValue.length > 0 ? 1 : 0;
        const index =
          characterIndex === alphabet.length - 1
            ? 0
            : characterIndex + initialValue;
        itemsRef.current[slotIndex].value = alphabet[index];

        setCharacterIndex(index);
      }

      onNameEntry(itemsRef.current[slotIndex].value, slotIndex);
    },
    [characterIndex, currentSlot, onNameEntry]
  );

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, itemsArray.length);
  }, [itemsArray]);

  useEffect(() => {
    itemsRef.current[0].focus();
  }, []);

  return (
    <div className="relative z-10 max-w-[16em] flex justify-center space-x-4 relative m-auto text-center md:text-l lg:text-3xl">
      {toIntArray(maxCharacters).map((i) => (
        <div key={`input-box-${i}`} className="relative w-[18%]">
          <MinimalButton
            onClick={() => handleButtonUp(i)}
            className="w-full max-w-[20%] md:max-w-[70%] flex justify-center absolute left-1/2 -translate-x-1/2 top-3 text-xs lg:text-base lg:leading-8"
            theme="blue"
            disabled={isDesktop}>
            <img
              src={rightArrowActive}
              alt="Right"
              className="-rotate-90"
              style={{
                display: isDesktop ? `none` : `block`
              }}
            />
          </MinimalButton>
          <input
            readOnly={!isDesktop}
            ref={(el) => {
              itemsRef.current[i] = el;
            }}
            className="align-middle w-full h-[5rem] md:h-[9rem] py-3 block text-center outline-none focus-none uppercase font-pixel text-application-blue"
            type="text"
            id="name"
            name="name"
            inputMode={isDesktop ? `text` : `none`}
            placeholder="A"
            required
            maxLength={1}
            tabIndex={-i}
            onKeyUp={(e) => handleKeyUp(i, e.key, e.target.value)}
          />
          <MinimalButton
            onClick={() => handleButtonDown(i)}
            className="w-full max-w-[20%] md:max-w-[70%] flex justify-center absolute left-1/2 -translate-x-1/2 bottom-0 text-xs lg:text-base lg:leading-8 md:border-t-4 border-black"
            theme="blue"
            disabled={isDesktop}>
            <img
              src={rightArrowActive}
              alt="Right"
              className="rotate-90"
              style={{
                display: isDesktop ? `none` : `block`
              }}
            />
          </MinimalButton>
        </div>
      ))}
    </div>
  );
};

export default NameEntry;
