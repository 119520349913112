import React from 'react';

export const MinimalButton = ({
  children = [],
  disabled = false,
  onClick = () => {},
  ...props
}) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className=""
      onClick={onClick}
      {...props}>
      {children}
    </button>
  );
};

export default MinimalButton;
