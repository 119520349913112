import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback
} from 'react';

import { CookiesProvider, useCookies } from 'react-cookie';
import { ContentFitter } from './ContentFitter';
import { GameContext } from '../../hooks/gameContext';
import { gameSettings, baseQuizScore } from '../../../settings';
import { HUD } from './HUD';
import { IntroBanner } from './IntroBanner';
import { Start } from './Start';
import { Tutorial } from './Tutorial';
import { GameOver } from './GameOver';
import { SubmitScore } from './SubmitScore';
import { Leaderboard } from './Leaderboard';
import { Quiz } from './Quiz';

import {
  incrementFirstPlaythrough,
  incrementSubmittedScore,
  incrementPressedStartInitial,
  incrementPressedStart,
  refreshHighestScore,
  refreshLowestScore
} from '../../../api/api';

import '../../../assets/fonts/Pixeled.ttf';
import '../../../assets/fonts/Inter-Regular.ttf';
import '../../../global-styles/styles.css';

export default ({
  name,
  score,
  lives,
  collectibles = [],
  firstPlaythrough = true,
  children,
  onSubmitUserData = (data) => {},
  onGameRestart = () => {}
}) => {
  /**
   * Hooks in the game and UI container for screen focus
   */
  const appRef = useRef(null);

  /**
   * Accessed to trigger game start on button press
   */
  const { gameInstance } = useContext(GameContext);

  /**
   * Screen mode states
   */
  const [isDesktop, setIsDesktop] = useState(false);

  /**
   * New player state
   */
  const [cookies, setCookie] = useCookies([
    `revisited`,
    `finishedFirstPlaythrough`
  ]);

  const { revisited, finishedFirstPlaythrough } = cookies;

  /**
   * Game states & data
   */
  const [isGameOver, setIsGameOver] = useState(false);
  const [collectiblesGained, setCollectiblesGained] = useState(0);
  const [quizScore, setQuizScore] = useState(0);

  /**
   * Panel visibility states
   */
  const [showIntro, setShowIntro] = useState(true);
  const [showStart, setShowStart] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showGameOver, setShowGameOver] = useState(false);
  const [showSubmitScore, setShowSubmitScore] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);

  /**
   * Event handlers for panels
   */
  const closeAll = () => {
    setShowIntro(false);
    setShowStart(false);
    setShowTutorial(false);
    setShowGameOver(false);
    setShowSubmitScore(false);
    setShowLeaderboard(false);
    setShowQuiz(false);
  };

  const handleOnStartClick = () => {
    closeAll();
    setShowStart(true);
  };

  const handleOnTutorialClick = () => {
    closeAll();
    setShowTutorial(true);
  };

  const handleOnLeaderboardClick = () => {
    closeAll();
    setShowLeaderboard(true);
  };

  const handleShowGameOverPanel = () => {
    closeAll();
    setShowGameOver(true);
  };

  const handleShowSubmitScorePanel = () => {
    closeAll();
    setShowSubmitScore(true);
  };

  const handleShowQuizPanel = () => {
    closeAll();
    setShowQuiz(true);
  };

  const handleSubmitScore = (data) => {
    closeAll();

    /* Both cookie's must be set specifically as bools, and have taken the correct flow */
    if (revisited === true && finishedFirstPlaythrough === false) {
      setCookie(`finishedFirstPlaythrough`, true, { path: `/` });
      incrementFirstPlaythrough();
    }

    incrementSubmittedScore();
    refreshHighestScore();
    refreshLowestScore();

    onSubmitUserData(data);
    setShowLeaderboard(true);
  };

  const handleSubmitQuizAnswers = (data) => {
    const sum = data.reduce((accumulator, { isCorrect }) => {
      const appendScore = isCorrect === true ? baseQuizScore : 0;
      return accumulator + appendScore;
    }, 0);

    setQuizScore(sum);

    closeAll();
    setShowSubmitScore(true);
  };

  const handleOnClose = () => {
    closeAll();
    setShowIntro(true);
  };

  /**
   * Event handles for game
   */
  const handleOnGameStartClick = useCallback(() => {
    closeAll();

    /** Player is new, but hasn't yet clicked start for the first time */
    if (revisited === true && typeof finishedFirstPlaythrough === `undefined`) {
      incrementPressedStartInitial();
      setCookie(`finishedFirstPlaythrough`, false, { path: `/` });
    } else if (
      revisited === true &&
      typeof finishedFirstPlaythrough !== `undefined`
    ) {
      incrementPressedStart();
    }

    setShowStart(false);
    onGameRestart();
    gameInstance.start();
  }, [
    finishedFirstPlaythrough,
    gameInstance,
    onGameRestart,
    revisited,
    setCookie
  ]);

  /**
   * Effects
   */
  useEffect(() => {
    if (
      typeof revisited === `undefined` &&
      typeof finishedFirstPlaythrough === `undefined`
    ) {
      setCookie(`revisited`, true, { path: `/` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCollectiblesGained(collectibles.filter((x) => x.collected).length);
  }, [collectibles]);

  useEffect(() => setShowGameOver(isGameOver), [isGameOver]);

  useEffect(() => {
    setIsGameOver(false);
    if (lives <= 0) {
      gameInstance.end(() => {
        setIsGameOver(true);
      });
    } else if (lives < gameSettings.maxLives) {
      gameInstance.respawn();
    }
  }, [gameInstance, lives]);

  return (
    <CookiesProvider>
      <ContentFitter
        scrollFocus={appRef}
        onDesktopQuery={(state) => setIsDesktop(state)}>
        {showIntro && (
          <IntroBanner
            isDesktop={isDesktop}
            onStart={handleOnStartClick}
            onTutorial={handleOnTutorialClick}
            onLeaderboard={handleOnLeaderboardClick}
          />
        )}
        {showStart && <Start onGameStart={handleOnGameStartClick} />}
        {showTutorial && (
          <Tutorial onGameStart={handleOnStartClick} onClose={handleOnClose} />
        )}
        {showGameOver && (
          <GameOver
            score={score + quizScore}
            collectibles={collectibles}
            onRestart={handleOnStartClick}
            onShowSubmitScorePanel={handleShowSubmitScorePanel}
            onShowQuizPanel={handleShowQuizPanel}
            onClose={handleOnClose}
          />
        )}
        {showSubmitScore && (
          <SubmitScore
            score={score + quizScore}
            collectiblesGained={collectiblesGained}
            isDesktop={isDesktop}
            onRestart={handleOnStartClick}
            onSubmitScore={handleSubmitScore}
            onClose={handleShowGameOverPanel}
          />
        )}
        {showLeaderboard && (
          <Leaderboard
            name={name}
            score={score + quizScore}
            collectibles={collectibles}
            firstPlaythrough={firstPlaythrough}
            onRestart={handleOnStartClick}
            onClose={handleOnClose}
          />
        )}
        {showQuiz && (
          <Quiz
            onClose={handleOnClose}
            onSubmitAnswers={handleSubmitQuizAnswers}
          />
        )}
        <div className="absolute top-0 left-0 w-full h-full">
          <HUD lives={lives} score={score} isReady={showStart} />
        </div>
        {children}
      </ContentFitter>
    </CookiesProvider>
  );
};
