import React, { useMemo, useEffect, useState } from 'react';
import { AspectRatio } from '../elements/AspectRatio';
import { OrientationPrompt } from './OrientationPrompt';

export const ContentFitter = ({
  children,
  scrollFocus,
  onDesktopQuery = (state) => {}
}) => {
  /**
   * Screen queries and initial states
   */
  const desktopQuery = useMemo(
    () =>
      window.matchMedia(
        `(min-device-width : 1024px) and (min-device-height : 768px)`
      ),
    []
  );

  const portrait = useMemo(
    () => window.matchMedia(`(orientation: portrait)`),
    []
  );

  const [showPrompt, setShowPrompt] = useState(portrait.matches);

  /**
   * Effects
   */
  useEffect(() => {
    setShowPrompt(portrait.matches);
  }, [portrait, portrait.matches]);

  useEffect(() => {
    const handleOrientationChange = (e) => {
      setShowPrompt(e.matches);
    };

    portrait.addEventListener(`change`, handleOrientationChange);

    return () => {
      portrait.removeEventListener(`change`, handleOrientationChange);
    };
  }, [portrait]);

  useEffect(() => {
    onDesktopQuery(desktopQuery.matches);
  }, [onDesktopQuery, desktopQuery.matches]);

  return (
    <div className="lg:flex lg:justify-center lg:items-center lg:h-screen">
      <div className="max-w-[1280px] w-full mx-auto">
        <div
          className="relative mx-auto fitter-wrapper"
          style={{
            width: showPrompt ? `100vw` : `177.7777vh`,
            maxWidth: `100%`
          }}>
          <AspectRatio
            portrait={showPrompt}
            className={`fitter-ratio ${
              showPrompt ? `prompted` : `not-prompted`
            }`}>
            {showPrompt && <OrientationPrompt />}
            <div className="w-full h-full bg-application-dark-blue m-auto max-h-[none]">
              {children}
            </div>
            <div ref={scrollFocus} />
          </AspectRatio>
        </div>
      </div>
    </div>
  );
};

export default ContentFitter;
