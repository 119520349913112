import Phaser from 'phaser';

/**
 * Entity inherits from the Phaser Arcade Sprite base class
 */
class Entity extends Phaser.Physics.Arcade.Sprite {
  /**
   * Create the entity
   * @param {Object} scene - The object instance of the scene this entity belongs to
   * @param {number} x - The initial x position of the entity
   * @param {number} y - The initial y position of the entity
   * @param {string} graphic - The graphical resource to use
   * @param {Object} optionalData - Any optionalData specified at init
   */
  constructor(scene, x, y, graphic, optionalData = {}) {
    super(scene, x, y, graphic);
    scene.physics.add.existing(this);

    this.body.setAllowGravity(false);
    this.setImmovable(true);

    this.dead = false;
    this.optionalData = optionalData;
    this.dataType = optionalData.type;
    this.dataId = optionalData.id;

    scene.add.existing(this);
  }

  /**
   * Getter for the collectibles 'isDead' property
   * @property {boolean} isDead - returns true if collectible has perished
   */
  get isDead() {
    return this.dead;
  }

  /**
   * Setter for the collectibles 'isDead' property
   * @param {boolean} value - set the state of collectible being dead
   */
  set isDead(value) {
    this.dead = value;
  }

  /**
   * Resets the collectible after being respawned
   * @param {number} x - optional spawn x position of the collectible
   * @param {number} y - optional spawn y position of the collectible
   */
  revive(x, y) {
    this.active = true;
    this.visible = true;
    this.dead = false;

    if (this.optionalData.type === `obstacle`) {
      this.body.setSize(
        this.width - this.width / 2,
        this.height - this.height / 2
      );
    } else {
      this.body.setSize(this.width, this.height);
    }

    if (x !== null && y !== null) {
      this.setPos(x, y);
    }
  }

  /**
   * Directly sets the position of the collectible
   * @param {number} x - x position of the collectible
   * @param {number} y - y position of the collectible
   */
  setPos(x, y) {
    this.x = x;
    this.y = y;
  }
}

export default Entity;
