export const fnWait = (ms = 100) => new Promise((r) => setTimeout(r, ms));

export const fnThrottle = (ms = 500) => {
  let prev = 0;

  return {
    exec: (callback = (now) => {}) => {
      const now = new Date().getTime();
      if (now - prev > ms) {
        prev = now;
        callback(now);
      }
    }
  };
};

/* A set of lambdas to keep things tidier */
export const fnRound = (a) => Math.round(a);
export const fnCeil = (a) => Math.ceil(a);
export const fnFloor = (a) => Math.floor(a);
export const fnAbs = (a) => Math.abs(a);
export const fnRoute = (a, b, condition) => (condition ? a : b);
export const fnMax = (a, b) => (a > b ? b : a);
export const fnMin = (a, b) => (a < b ? b : a);
export const fnAdd = (a, b) => a + b;
export const fnSub = (a, b) => a - b;
export const fnMult = (a, b) => a * b;
export const fnDiv = (a, b) => a / b;
