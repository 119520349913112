/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getAnalytics } from '../../api/api';
import { Heading } from '../components/elements/Heading';

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initialRequest = async () => {
      const {
        data: { Responses }
      } = await getAnalytics();

      if (Responses === null) {
        setIsLoading(false);
        return;
      }

      const [firstEntry] = Object.values(Responses);
      const mappedEntryItems = firstEntry.map(
        ({
          AnalyticsId: { S: id },
          AnalyticsName: { S: nameValue },
          AnalyticsNumber: { N: value }
        }) => ({
          id,
          title: nameValue.replace(/([a-z])([A-Z])/g, `$1 $2`),
          value:
            id === `lastplaythrough`
              ? new Date(parseInt(value, 10)).toString()
              : value
        })
      );

      setAnalyticsData(mappedEntryItems);
      setIsLoading(false);
    };

    setIsLoading(true);
    initialRequest();
  }, []);

  return (
    <div className="w-full h-screen bg-[#E6E6E6]">
      <div className="w-4/6 m-auto absolute-center">
        <Heading className="p-4" fontSize="text-lg lg:text-2xl">
          Analytics
        </Heading>
        {analyticsData.length > 0 ? (
          <table className="table-auto">
            <thead>
              <tr>
                <th>Metric:</th>
                <th>Value:</th>
              </tr>
            </thead>
            <tbody>
              {analyticsData.map(({ id, title, value }) => {
                return (
                  <tr key={id}>
                    <td>{title}:</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="p-4">
            {isLoading ? `Loading results...` : `No results.`}
          </p>
        )}
      </div>
    </div>
  );
};

export default Analytics;
