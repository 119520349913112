import React from 'react';

export const AspectRatio = ({
  children,
  className = ``,
  style = {},
  customPadding = null,
  portrait = true
}) => {
  const overrideStyle = customPadding
    ? {
        paddingTop: customPadding,
        aspectRatio: portrait ? `auto` : `16/9`
      }
    : { aspectRatio: portrait ? `auto` : `16/9` };

  const styles = { ...overrideStyle, ...style };

  return (
    <div
      className={`overflow-hidden mx-auto aspect-ratio-box${
        className.length ? ` ${className}` : ``
      }`}
      style={styles}>
      <div className="aspect-ratio-box-inside">{children}</div>
    </div>
  );
};

export default AspectRatio;
