import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import IndexPage from './react/pages/index';
import AnalyticsPage from './react/pages/analytics';

const router = createBrowserRouter([
  {
    path: `/`,
    element: <IndexPage />
  },
  {
    path: `/axis-csg-analytics`,
    element: <AnalyticsPage />
  }
]);

const AppRoot = () => {
  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
};

const rootElement = document.getElementById(`root`);
const root = createRoot(rootElement);

root.render(<AppRoot />);
