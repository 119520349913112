import React, { useState, useContext, useEffect, useCallback } from 'react';
import { pad, toIntArray } from '../../../helpers/interface';
import { gameSettings } from '../../../settings';
import { Button } from '../elements/Button';
import heartFull from '../../../assets/images/heart-full.svg';
import heartEmpty from '../../../assets/images/heart-empty.svg';
import soundGraphic from '../../../assets/images/sound.png';
import TopBar from '../../../assets/images/top-bar.svg';
import BottomBar from '../../../assets/images/bottom-bar.svg';
import AxisLogo from '../../../assets/images/axis-logo.svg';
import chevronUp from '../../../assets/images/chevron-up.png';
import chevronDown from '../../../assets/images/chevron-down.png';
import { GameContext } from '../../hooks/gameContext';

export const HUD = ({ lives, score, isReady = false }) => {
  const { gameInstance } = useContext(GameContext);

  const [soundOn, setSoundOn] = useState(false);

  const handleOnSoundToggle = useCallback(() => {
    const toggled = !soundOn;
    setSoundOn(toggled);
  }, [soundOn]);

  const handleJumpSignal = useCallback(() => {
    if (gameInstance !== null) {
      gameInstance.jumpSignal();
    }
  }, [gameInstance]);

  const handleSlideSignal = useCallback(() => {
    if (gameInstance !== null) {
      gameInstance.slideSignal();
    }
  }, [gameInstance]);

  useEffect(() => {
    if (gameInstance !== null && gameInstance.gameBooted()) {
      gameInstance.toggleSound(soundOn);
    }
  }, [gameInstance, soundOn, isReady]);

  return (
    <div className="w-full h-full relative z-[2] clickthrough">
      <div className="w-full h-full relative flex justify-left items-top">
        <div className="text-white flex flex-row lg:mt-4 lg:ml-4 lg:max-w-full sm:mt-3 sm:ml-3 sm:max-w-[5rem]">
          <div className="w-full h-auto absolute left-0 top-0">
            <img className="w-full" src={TopBar} alt="Top Bar" />
          </div>
          {toIntArray(gameSettings.maxLives).map((i) => {
            return (
              <div key={`lives-${i}`} className="relative w-full h-full mr-2">
                {i < lives ? (
                  <img
                    src={heartFull}
                    alt="lives"
                    className="life pixel-img object-cover"
                  />
                ) : (
                  <img
                    src={heartEmpty}
                    alt="lives"
                    className="life pixel-img object-cover"
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="h-full text-white font-pixel ml-auto lg:mt-3 lg:mr-3 sm:mt-2 sm:mr-2 z-10">
          <h3 className="score-value lg:text-lg sm:text-sm">{pad(score, 3)}</h3>
        </div>
        <div className="w-full h-auto absolute bottom-0 left-0 overflow-hidden">
          <div className="w-full flex xl:hidden relative z-10 py-[0.25rem] opacity-50">
            <Button
              wrapperClass="px-[0.8rem]"
              className="h-[5rem] clickon"
              shadow={false}
              onClick={handleJumpSignal}>
              <img
                className="pixel-img inline-pixel-chevron"
                src={chevronUp}
                alt="Up"
              />
            </Button>
            <Button
              wrapperClass="ml-auto px-[0.8rem]"
              className="h-[5rem] clickon"
              shadow={false}
              onClick={handleSlideSignal}>
              <img
                className="pixel-img inline-pixel-chevron"
                src={chevronDown}
                alt="Up"
              />
            </Button>
          </div>
          <div className="lg:w-[40px] lg:h-[36px] sm:w-[20px] sm:h-[18px] lg:ml-4 lg:mb-4 sm:ml-3 sm:mb-3 overflow-hidden absolute left-0 bottom-0 z-10">
            <button
              type="submit"
              className="text-white clickon"
              onClick={handleOnSoundToggle}>
              <span
                className={`lg:w-[40px] lg:h-[36px] sm:w-[20px] sm:h-[18px] absolute left-0 top-0 mask-test${
                  !soundOn ? ` empty` : ``
                }`}>
                <img
                  src={soundGraphic}
                  alt="toggle sound"
                  className="lg:min-w-[80px] lg:min-h-[36px] sm:min-w-[40px] sm:min-h-[18px] pixel-img"
                />
              </span>
            </button>
          </div>
          <div className="overflow-hidden absolute right-2 bottom-2 z-10 w-100% h-auto max-w-[5rem] min-w-[8%]">
            <img className="w-full" src={AxisLogo} alt="AXIS" />
          </div>
          <div className="w-full h-auto relative flex justify-left items-bottom">
            <img className="w-full" src={BottomBar} alt="Bottom Bar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HUD;
