import React from 'react';
import ReactMarkdown from 'react-markdown';

export default ({ className }) => (
  <ReactMarkdown
    className={className.concat(` disclaimer`)}
    linkTarget="_blank">
    {`By submitting your score for this game, you agree to AXIS processing your player ID and score. To
understand more about how we handle data, please see the [AXIS Privacy
Notice](https://www.axiscapital.com/who-we-are/privacy-notice).  

This material is for general information, education and discussion
purposes only. Statements contained herein are not professional or
legal advice of AXIS Specialty U.S. Services, Inc. or its affiliates.
The material is offered as a resource that may be used together with
input from professional insurance advisors to better understand and
mitigate cyber risk. Although every effort has been made to ensure
quality, AXIS makes no representations as to the accuracy or
completeness of the information contained herein, and assumes no
liability by providing this material.`}
  </ReactMarkdown>
);
