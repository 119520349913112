import React from 'react';

export const Panel = ({
  children = [],
  width = `full`,
  height = `full`,
  className = ``
}) => {
  const baseClass = `w-${width} h-${height} relative px-12 py-4 md:py-8 bg-white text-center pixel-this `;
  return <div className={baseClass.concat(className)}>{children}</div>;
};

export default Panel;
