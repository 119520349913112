/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-parens */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import parse from 'html-react-parser';
import { SubHeading } from '../elements/SubHeading';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Button } from '../elements/Button';
import { LargeButton } from '../elements/LargeButton';
import closeGraphic from '../../../assets/images/close-button.png';
import { quizQuestions, quizGlobalContent } from '../../../settings';
import { questionPicker } from '../../../helpers/chance';
import { fnWait } from '../../../helpers/functions';

export const Quiz = ({ onClose = () => {}, onSubmitAnswers = () => {} }) => {
  const pickedQuestions = useMemo(() => questionPicker.pick(quizQuestions), []);

  const [parseAsHTML, setParseAsHTML] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const [summaryContent, setSummaryContent] = useState(null);
  const [quizAnswerData, setQuizAnswerData] = useState([]);
  const [buttonResult, setButtonResult] = useState(`blue`);
  const [lastChosenIndex, setLastChosenIndex] = useState(null);
  const [inputLocked, setInputLocked] = useState(false);

  const handleAnswerClicked = useCallback(
    async (expectedIndex, chosenIndex) => {
      if (inputLocked === true) {
        return;
      }

      const { isHTML, summary, answers } = pickedQuestions[questionIndex];

      const isCorrect = expectedIndex === chosenIndex;

      const correctText = summary.length > 0 ? summary : ``;
      const incorrectText = `Correct Answer: "${answers[expectedIndex]}"${
        summary.length > 0 ? ` - ${summary}` : `.`
      }`;

      setLastChosenIndex(chosenIndex);
      setButtonResult(isCorrect ? `green` : `red`);
      setInputLocked(true);

      await fnWait(200);

      setInputLocked(false);
      setLastChosenIndex(null);
      setParseAsHTML(isHTML);

      setSummaryContent({
        summary: isCorrect === true ? correctText : incorrectText
      });

      setAnswerCorrect(isCorrect);
      setQuizAnswerData([...quizAnswerData, { questionIndex, isCorrect }]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionIndex, quizAnswerData, pickedQuestions]
  );

  const handleNextQuestionClicked = useCallback(() => {
    const nextIndex = questionIndex + 1;

    setSummaryContent(null);
    setQuestionIndex(nextIndex);
    setCurrentQuestion(pickedQuestions[nextIndex]);
  }, [questionIndex, pickedQuestions]);

  const handleSubmitAnswers = useCallback(() => {
    onSubmitAnswers(quizAnswerData);
  }, [quizAnswerData]);

  useEffect(() => {
    const [firstQuestion] = pickedQuestions;
    setCurrentQuestion(firstQuestion);
  }, []);

  return currentQuestion !== null ? (
    <Content expand className="relative z-50">
      <div className="absolute top-7 right-1 lg:top-10 lg:right-10">
        <Button className="w-[70%] lg:w-auto" shadow={false} onClick={onClose}>
          <img src={closeGraphic} alt="Close" />
        </Button>
      </div>
      {summaryContent !== null ? (
        <Panel className="flex flex-col justify-between">
          <div>
            <SubHeading className="pb-1 text-application-blue">
              {`Question ${questionIndex + 1}/${pickedQuestions.length}`}
            </SubHeading>
            {answerCorrect === true ? (
              <SubHeading className="text-black pb-2 lg:pb-8">
                {quizGlobalContent.correctHeading}
              </SubHeading>
            ) : (
              <SubHeading className="text-black pb-2 lg:pb-8">
                {quizGlobalContent.incorrectHeading}
              </SubHeading>
            )}
          </div>
          {parseAsHTML === true ? (
            <div className="relative z-10 max-w-[80%] sm:max-w-[100%] mx-auto leading-1 lg:leading-8 font-inter font-medium text-sm lg:text-lg">
              {parse(summaryContent.summary)}
            </div>
          ) : (
            <ReactMarkdown
              className="max-w-[80%] sm:max-w-[100%] mx-auto leading-1 lg:leading-8 font-inter font-medium text-sm lg:text-lg"
              remarkPlugins={[remarkGfm]}>
              {summaryContent.summary}
            </ReactMarkdown>
          )}

          <div className="flex justify-center mt-2">
            {questionIndex >= pickedQuestions.length - 1 ? (
              <Button theme="green" onClick={handleSubmitAnswers}>
                Submit Answers
              </Button>
            ) : (
              <Button theme="blue" onClick={handleNextQuestionClicked}>
                Next Question
              </Button>
            )}
          </div>
        </Panel>
      ) : (
        <Panel className="flex flex-col justify-between">
          <SubHeading className="pb-1 sm:pb-3 text-application-blue">
            {`Question ${questionIndex + 1}/${pickedQuestions.length}`}
          </SubHeading>
          <ReactMarkdown
            className="max-w-[50%] sm:max-w-[100%] mx-auto font-inter font-medium text-md lg:text-xl"
            remarkPlugins={[remarkGfm]}>
            {currentQuestion.question}
          </ReactMarkdown>
          <div className="max-w-[80%] sm:max-w-[100%] w-full mx-auto pb-12 flex flex-row flex-wrap justify-center">
            {currentQuestion.answers.map((answer, i) => {
              return (
                <LargeButton
                  key={`quiz-question-${currentQuestion.id}-answer-${i}`}
                  theme={lastChosenIndex === i ? buttonResult : `blue`}
                  className="w-full h-16 lg:h-24 xl:h-32 text-sm md:text-md lg:text-lg sm:leading-5"
                  disabled={inputLocked === true}
                  onClick={() =>
                    handleAnswerClicked(currentQuestion.expected, i)}>
                  {answer}
                </LargeButton>
              );
            })}
          </div>
        </Panel>
      )}
    </Content>
  ) : (
    <Content expand className="relative z-50">
      <Panel className="flex flex-col justify-between">
        Getting questions...
      </Panel>
    </Content>
  );
};

export default Quiz;
