import Phaser from 'phaser';
import { PLAYER, IDLE, JUMP, RUN, KO, FALL, SLIDE } from '../../settings';

/**
 * Player inherits from the Phaser Arcade Sprite base class
 */
class Player extends Phaser.Physics.Arcade.Sprite {
  /**
   * Create the player
   * @param {Object} scene - The object instance of the scene this entity belongs to
   * @param {number} x - The initial x position of the entity
   * @param {number} y - The initial y position of the entity
   */
  constructor(scene, x, y) {
    super(scene, x, y, PLAYER);
    scene.physics.add.existing(this);

    this.setBounce(0);
    this.setCollideWorldBounds(false);
    this.setDepth(2);

    this._speed = 0.3;
    this._crouched = false;
    this.dead = false;
    this.invincible = false;
    this.bodyNeedsUpdate = false;

    this.body.setFrictionX(0);
    this.body.setSize(this.width / 4, this.height);
    this.body.setOffset(this.width / 2 - this.width / 8, 0);

    this.anims.play(IDLE, true);

    this.playerGroup = scene.add.group();
    this.playerPool = scene.add.group();

    this.playerGroup.add(this);

    scene.add.existing(this);

    this.playerKoSprite = scene.add.sprite(this.x, this.y, KO);

    scene.physics.add.existing(this.playerKoSprite);

    this.playerKoSprite.body.setCollideWorldBounds(true);
    this.playerKoSprite.body.setBounce(0.3);
    this.playerKoSprite.body.setEnable(false);
    this.playerKoSprite.setDepth(20);
    this.playerKoSprite.active = false;
    this.playerKoSprite.visible = false;

    this.playerSlideSprite = scene.add.sprite(0, 0, SLIDE);

    this.playerSlideSprite.setDepth(20);
    this.playerSlideSprite.active = false;
    this.playerSlideSprite.visible = false;
  }

  /**
   * Getter for the players 'crouched' property
   * @property {boolean} crouched - returns true if player has perished
   */
  get isCrouched() {
    return this._crouched;
  }

  /**
   * Setter for the players 'isCrouched' property
   * @param {number} value - set the state of crouched
   */
  set isCrouched(value) {
    this._crouched = value;
  }

  /**
   * Getter for the players 'isDead' property
   * @property {boolean} isDead - returns true if player has perished
   */
  get isDead() {
    return this.dead;
  }

  /**
   * Getter for the players 'isGrounded' property
   * @property {boolean} isGrounded - returns true if player is on the ground
   */
  get isGrounded() {
    return this.body.blocked.down;
  }

  /**
   * Setter for the players 'speed' property
   * @param {number} value - set the state of players run speed
   */
  set speed(value) {
    this._speed = value;
  }

  /**
   * Getter for the players run speed
   */
  get speed() {
    return this._speed;
  }

  /**
   * Resets the player after being respawned
   * @param {number} x - optional spawn x position of the collectible
   * @param {number} y - optional spawn y position of the collectible
   */
  revive(x, y) {
    this.anims.timeScale = 1;
    this.active = true;
    this.visible = true;
    this.dead = false;

    // TODO: This isn't really needed
    this.playerGroup.add(this);
    this.playerPool.remove(this);

    if (x !== null && y !== null) {
      this.setPos(x, y);
    }

    this.playerKoSprite.active = false;
    this.playerKoSprite.visible = false;
    this.playerKoSprite.setPosition(0, 0);
    this.playerKoSprite.body.setEnable(false);

    const player = this;

    player.invincible = true;

    this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 100,
      repeat: 10,
      yoyo: true,
      onComplete: () => {
        player.alpha = 1;
        player.invincible = false;
      }
    });
  }

  /**
   * Kills the player until revival
   */
  kill() {
    this.anims.timeScale = 1;
    this.dead = true;
    this.active = false;
    this.visible = false;
    this._crouched = false;
    this.playerGroup.remove(this);
    this.playerPool.add(this);

    this.playerSlideSprite.active = false;
    this.playerSlideSprite.visible = false;

    this.playerKoSprite.visible = true;
    this.playerKoSprite.body.setEnable(true);
    this.playerKoSprite.x = this.x;
    this.playerKoSprite.y = this.y;

    this.scene.tweens.add({
      targets: this.playerKoSprite,
      props: {
        x: {
          value: this.playerKoSprite.x - 6
        }
      },
      duration: 30,
      yoyo: true,
      repeat: 5
    });
  }

  /**
   * Instructs player to jump
   * @param {number} withVelocity - how much strength to jump with
   * @param {bool} checkGround - whether or not to check for ground collision
   */
  jump(withVelocity, checkGround = true) {
    if (this.isCrouched === true || this._speed === 0) {
      return;
    }

    if (checkGround && this.isGrounded === true) {
      this.body.setVelocityY(withVelocity);
      this.canDoubleJump = true;
    } else if (this.canDoubleJump) {
      this.canDoubleJump = false;
      this.body.setVelocityY(withVelocity);
    } else if (!checkGround) {
      this.body.setVelocityY(withVelocity);
    }

    this.body.setSize(this.width / 4, this.height - 8);
    this.body.setOffset(this.width / 2 - this.width / 8, 0);
  }

  /**
   * Instructs the player to crouch
   */
  crouch() {
    if (
      this._crouched ||
      this.isDead === true ||
      this._speed === 0 ||
      this.isGrounded === false
    ) {
      return;
    }

    this._crouched = true;
    this.visible = false;

    this.playerSlideSprite.visible = true;
    this.playerSlideSprite.setPosition(this.x, this.y);

    this.body.reset(this.x, this.y + 10);
    this.body.setSize(this.width, this.height / 3);

    this.scene.time.delayedCall(
      450,
      () => {
        this.stand();
      },
      [],
      this
    );
  }

  /**
   * Instructs the player to stand
   */
  stand() {
    if (this.isDead === true || this._speed === 0) {
      return;
    }

    this._crouched = false;
    this.visible = true;

    this.playerSlideSprite.active = false;
    this.playerSlideSprite.visible = false;

    this.body.setSize(this.width / 4, this.height);
    this.body.setOffset(this.width / 2 - this.width / 8, 0);
  }

  /**
   * Update method called on game tick
   */
  update() {
    this.anims.timeScale = this._speed;

    if (this.isCrouched) {
      return;
    }

    if (this.isGrounded === false) {
      this.anims.play(this.body.velocity.y > 0 ? FALL : JUMP, true);
      if (this.body.velocity.y > 150) {
        this.body.setSize(this.width / 4, this.height);
        this.body.setOffset(this.width / 2 - this.width / 8, 0);
      }
    } else {
      this.anims.play(RUN, true);
    }
  }

  /**
   * Directly sets the position of the player
   * @param {number} x - x position of the player
   * @param {number} y - y position of the player
   */
  setPos(x, y) {
    this.x = x;
    this.y = y;
  }
}

export default Player;
