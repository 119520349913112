import React, { useMemo, useState } from 'react';
import { useIfNothingNull } from '../../hooks/useIfNothingNull';
import { GameContext } from '../../hooks/gameContext';
import { createGameObject } from '../../../phaser/Game';

export const GameProvider = ({
  gameRef,
  children,
  onSceneUpdate = () => {},
  onCollectiblePickup = (key) => {},
  onObstacleCollision = () => {}
}) => {
  const gameInstance = useMemo(() => createGameObject(), []);
  const [bootLock, setBootLock] = useState(false);

  useIfNothingNull(
    (deps) => {
      if (bootLock) return;
      gameInstance.initialize({
        onSceneUpdate,
        onCollectiblePickup,
        onObstacleCollision
      });

      setBootLock(true);
    },
    [gameRef],
    (deps) => deps.every((x) => x !== null)
  );

  return (
    <GameContext.Provider
      value={{
        gameInstance,
        initializeGame: () => gameInstance.initialize()
      }}>
      {children}
    </GameContext.Provider>
  );
};

export default GameProvider;
