import { getRandomInt } from '../../helpers/chance';

import {
  gameSettings,
  weights,
  spawnPatterns,
  specialPatterns
} from '../../settings';

export const respawnWave = (entities, lastCachedPosition) => {
  const nextPattern = spawnPatterns[getRandomInt(0, spawnPatterns.length - 1)];

  const yPadding = 18;
  const cellHeight = gameSettings.screenSize.height / nextPattern.length - 0.5;
  const spawnSpecialPattern = false; //Math.random() > 0.7; - Disabled until fixed

  let spawnedCollectible = false;

  if (spawnSpecialPattern === true) {
    const [chosenPattern] = specialPatterns;
    const { xPositions } = chosenPattern;
    const startFrom = getRandomInt(0, 5 - xPositions.length);

    for (let x = 0; x < xPositions.length; x++) {
      const getFirstInactive = entities.getFirstInactiveType({
        type: `obstacle`,
        shuffleOnSearch: true
      });

      entities.respawnNextEntity(
        // lastCachedPosition + pos * 32,
        lastCachedPosition,
        cellHeight + cellHeight * (startFrom + x) - getFirstInactive.height,
        getFirstInactive,
        x < xPositions.length - 1
      );
    }
  } else {
    for (let i = 0; i < nextPattern.length; i++) {
      const yPos = cellHeight * i;
      const offsetYPos = (yPadding + yPos) * 0.9;
      const currentEntry = nextPattern[i];
      /* Current rule:
       * 0 = Placement for nothing or collectible
       * 1 = Placement for obstacle */
      if (currentEntry === 1 && Math.random() <= weights.chanceOfObstacle) {
        const getFirstInactive = entities.getFirstInactiveType({
          type: `obstacle`,
          shuffleOnSearch: true
        });

        entities.respawnNextEntity(
          lastCachedPosition,
          offsetYPos,
          getFirstInactive
        );
      } else if (
        currentEntry === 2 &&
        spawnedCollectible === false &&
        Math.random() <= weights.chanceOfCollectible
      ) {
        spawnedCollectible = true;

        const getFirstInactive = entities.getFirstInactiveType({
          type: `collectible`,
          shuffleOnSearch: true
        });

        entities.respawnNextEntity(
          lastCachedPosition,
          offsetYPos,
          getFirstInactive
        );
      }
    }
  }
};

export default respawnWave;
