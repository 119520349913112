import React from 'react';

export const Button = ({
  children = [],
  shadow = true,
  theme = `default`,
  wrapperClass = ``,
  className = ``,
  disabled = false,
  pixelScale = 3, // TODO: Integrate in to classNames
  onClick = () => {},
  ...props
}) => {
  const wrapperBaseTheme = `mx-6 self-end pixel-button before:bg-black before:top-[9px] before:left-[9px] after:bg-black after:top-[9px] after:left-[0px]`;
  const baseTheme = `uppercase z-10 rounded px-4 m-[3px] leading-8 pixel-button font-pixel `;

  const themes = {
    [`green`]: `bg-button-green text-black before:bg-button-green after:bg-button-green`,
    [`blue`]: `bg-button-blue text-black before:bg-button-blue after:bg-button-blue`,
    [`default`]: `bg-default text-black before:bg-default after:bg-default`
  };

  return (
    <div
      className={
        shadow ? `${wrapperBaseTheme} ${wrapperClass}` : `${wrapperClass}`
      }
      style={{
        opacity: disabled ? 0.5 : 1
      }}>
      <button
        type="submit"
        disabled={disabled}
        className={`${baseTheme}${
          className.length ? `${className}` : `relative`
        } ${themes[theme]}`}
        onClick={onClick}
        {...props}>
        {children}
      </button>
    </div>
  );
};

export default Button;
