import React from 'react';

export const Heading = ({
  className = `pb-8`,
  fontSize = `text-base lg:text-4xl`,
  pixelFont = true,
  children = []
}) => {
  const fontFamily = pixelFont ? `font-pixel` : ``;
  const baseClass = `text-application-blue uppercase lg:leading-[3rem] ${fontFamily} ${fontSize} `;
  const classes = baseClass.concat(className);
  return <h3 className={classes}>{children}</h3>;
};

export default Heading;
