import React, { useEffect, useState } from 'react';
import { Busy } from './Busy';
import { Heading } from '../elements/Heading';
import { Content } from '../elements/Content';
import { Panel } from '../elements/Panel';
import { Button } from '../elements/Button';
import { ShareButton } from '../elements/ShareButton';
import { getLeaderboardData } from '../../../api/api';
import { pad } from '../../../helpers/interface';
import closeGraphic from '../../../assets/images/close-button.png';

export const Leaderboard = ({
  name,
  score,
  collectibles = [],
  maxEntries = 8,
  firstPlaythrough = true,
  onRestart = () => {},
  onClose = () => {}
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [rank, setRank] = useState(0);
  const [isInTopScores, setIsInTopScores] = useState(false);
  const [userLatestScoreTime, setUserLatestScoreTime] = useState(0);
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { Items, UserInTop, UserRank } = await getLeaderboardData({
        leaderboardScore: score,
        max: maxEntries
      });

      setIsBusy(false);
      setLeaderboardEntries(Items);

      if (score > 0 && name.length > 0) {
        setRank(UserRank);
        setIsInTopScores(UserInTop);

        const myScores = Items.filter((item) => item.name === name);

        if (myScores.length > 0) {
          /**
           * Use the first item (extracted by shift), which should equate to the latest date posted.
           */
          const latest = myScores.sort((a, b) => b.date - a.date).shift();
          setUserLatestScoreTime(latest.date);
        }
      }
    };

    setIsBusy(true);
    fetchData().catch(console.error);
  }, [maxEntries, name.length, name, score]);

  useEffect(() => {
    if (leaderboardEntries.length === 0) {
      return;
    }

    setFilteredEntries(leaderboardEntries);
  }, [maxEntries, score, leaderboardEntries]);

  return (
    <Content expand className="relative z-50">
      <Panel>
        <Busy isBusy={isBusy}>
          <Heading className="pb-2 lg:pb-8">Leaderboard</Heading>
          <div className="absolute top-7 right-0 lg:top-10 lg:right-10">
            <Button
              className="w-[70%] lg:w-auto"
              shadow={false}
              onClick={onClose}>
              <img src={closeGraphic} alt="Close" />
            </Button>
          </div>
          <p className="leading-8 font-pixel text-xs lg:text-base">
            Play again and see if you can beat your score.
          </p>
          <dl className="grid grid-rows-4 grid-flow-col gap-x-12 m-auto lg:mt-8 pt-4 pb-2 lg:mx-12 font-pixel text-xs lg:text-base">
            {filteredEntries
              .sort((a, b) => b.score - a.score)
              .map((item, i) => {
                return (
                  <dd
                    key={`${item.name}-${item.score}`}
                    className={`flex uppercase pb-2${
                      item.date === userLatestScoreTime && item.name === name
                        ? ` text-blue`
                        : ``
                    }`}>
                    <span className="max-w-8 mr-4">{pad(i + 1, 2)}</span>
                    <span className="mr-4">{item.name}</span>
                    <span className="ml-auto">{pad(item.score, 7)}</span>
                  </dd>
                );
              })}
          </dl>
          {!firstPlaythrough && !isInTopScores && (
            <div className="flex justify-center text-blue text-xs lg:text-base border-t-4 border-grey lg:mt-4 pt-1 md:pt-4 mx-12 font-pixel">
              <div className="uppercase">
                <span className="max-w-8 mr-4">{rank}</span>
                <span className="mr-4">{name}</span>
                <span className="pl-16 ml-auto">{pad(score, 7)}</span>
              </div>
            </div>
          )}
          <div className="flex justify-center mt-2 md:mt-4 lg:mt-8">
            <Button
              className="self-end text-xs lg:text-base lg:leading-8"
              theme="green"
              onClick={onRestart}>
              {firstPlaythrough ? `Start Playing` : `Replay`}
            </Button>
            <ShareButton
              className="self-end text-xs lg:text-base lg:leading-8"
              score={score}
              collectibles={collectibles}
              disabled={firstPlaythrough}>
              Share
            </ShareButton>
          </div>
        </Busy>
      </Panel>
    </Content>
  );
};

export default Leaderboard;
