import React from 'react';
import { Heading } from '../elements/Heading';

export const Busy = ({ isBusy = false, children }) => {
  return (
    <>
      {isBusy ? (
        <div className="h-full text-center relative z-[101]">
          <Heading>Working...</Heading>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Busy;
